import React, { useRef, useCallback, useState } from 'react'
import axios from 'axios';
import { Navigation } from "../../components/Navigation";
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useEffect } from 'react';
import { Button } from '../../components/Button';
import { Helmet } from 'react-helmet'
import { Loading_upload } from '../Loading';
import { Loading_CL_revise } from '../Loading';
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
import Form from 'react-bootstrap/Form';
import ReactQuill from 'react-quill';
import { MobileWarning } from "../../components/MobileWarning/MobileWarning"
import 'react-quill/dist/quill.snow.css'; // import styles for the editor
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------auhentication code----------------------------------------
import './DashboardRevision.css'

export const DashboardRevision = (props) => {
    //---------------------------------auhentication code----------------------------------------
    //auth0 component
    const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
    if (!isAuthenticated) {
        return <div>Please log in first to start revise your resume.</div>;
    }
    //---------------------------------auhentication code----------------------------------------
    const { pathname } = useLocation(); // destructuring the pathname from the useLocation hook
    const navigate = useNavigate();

    if (isAuthenticated && !user.email_verified) {
        return <div>Sorry, you need to verify your email first</div>;  // Display a message or loading animation here
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const quillRef = useRef(null);
    const quillClRef = useRef(null);
    const [loading, setLoading] = useState(false); // state for loading
    const [coverletterLoading, setCoverletterLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [coverletterApiResponse, setCoverletterApiResponse] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [enoughBalance, setEnoughBalance] = useState(false);
    const [dbResumeInfo, setDbResumeInfo] = useState(null);
    const [currentResume, setCurrentResume] = useState(null);

    const [selectedWorkOption, setSelectedWorkOption] = useState("resume");

    // resume
    const [targetCompany, setTargetCompany] = useState('');
    const [resumeCompanyDomain, setResumeCompanyDomain] = useState('');
    const [companySuggestions, setCompanySuggestions] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const typingTimeoutRef = useRef(null); // To store the timeout ID
    const inputRef = useRef(null);
    const dropdownRef = useRef(null); // Ref to track the dropdown element
    const [jobDescription, setJobDescription] = useState("");

    const handleQuillChange = (content) => {
        setJobDescription(content);
    };

    const isEditorEmpty = (editorRef) => {
        if (editorRef.current) {
          const editor = editorRef.current.getEditor(); // Get the Quill instance
          const text = editor.getText().trim(); // Get the plain text and trim whitespace
          return text.length === 0; // Returns true if there is no significant text
        }
        return true; // Default to true if no editor instance found
      };

    const [selectedLengthOption, setSelectedLengthOption] = useState("Standard");

    // cover letter
    const [companyName, setCompanyName] = useState("");
    const [clCompanyDomain, setclCompanyDomain] = useState('');
    const [positionTitle, setPositionTitle] = useState("");
    const [selectedStyleOption, setSelectedStyleOption] = useState("Modern");
    const [cultureValue, setCultureValue] = useState("");
    const [personalStory, setPersonalStory] = useState("");
    const [jobPosition, setJobposition] = useState(""); // state for target job position
    // const data = require('./test.json');
    // const location = useLocation();
    // data = location.state?.data;
    const degree = useRef("");
    const education = useRef("");
    const major = useRef("");
    const education_status = useRef("");
    const [coverletterJobDescription, setCoverletterJobDescription] = useState("");

    const handleQuillChangeCL = (content) => {
        setCoverletterJobDescription(content);
    }

    useEffect(() => {
        fetchUserBalance();
        fetchCurrentResume();
    }, [selectedWorkOption]);

    const fetchUserBalance = async () => {
        // send a POST request
        console.log('Send POST request to API')
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/check_user_status', { //api endpoint
            method: 'POST',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
            //---------------------------------auhentication code----------------------------------------
        }).then(response => {
            // Check for the 400 status code 
            // console.log('Server Response:', response);
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            //---------------------------------auhentication code----------------------------------------
            if (response.status == 401) {
                throw new Error('Sorry, you must login first to upload your resume')
            }
            //---------------------------------auhentication code----------------------------------------
            return response.json();
        }).then(data => {
            // Reset enoughBalance to false before checking conditions
            setEnoughBalance(false);
            if (data.in_subscription === true) {
                setEnoughBalance(true);
            }
            if (data.current_credits >= 100) {
                setEnoughBalance(true);
            }
            if (selectedWorkOption != 'resume' && data.current_credits >= 50) {
                setEnoughBalance(true);
            }
            //setLoading(false);
        }).catch(error => {
            // handle the error
            console.error("fetch user credits failed:", error);
            // If there's an error, ensure loading is turned off
            // Check if the error is due to a JSON parsing issue
            if (error.message.includes('Unexpected token')) {
                console.error('Received non-JSON response:', error);
                // You might want to set a different state here to handle this specific error
            }
            //setLoading(false);
            alert(error.message);  // Displaying the error message to the user
        });
    }

    const fetchCurrentResume = async() => {
        // send a POST request
        console.log('Send POST request to API')
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/get_dashboard_resume', { //api endpoint
            method: 'POST',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
            //---------------------------------auhentication code----------------------------------------
        }).then(response => {
            // Check for the 400 status code 
            // console.log('Server Response:', response);
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            //---------------------------------auhentication code----------------------------------------
            if (response.status == 401) {
                throw new Error('Sorry, you must login first')
            }
            //---------------------------------auhentication code----------------------------------------
            return response.json();
        }).then(data => {
            if (data) {
                setDbResumeInfo(data);
                let currentResumeValue = JSON.parse(data.Record_content);
                setCurrentResume(currentResumeValue);
                education.current = currentResumeValue?.Resume_info?.education?.[0]?.university || null;
                degree.current = currentResumeValue?.Resume_info?.education?.[0]?.degrees || null;
                major.current = currentResumeValue?.Resume_info?.education?.[0]?.majors || null;
                education_status.current = determineEducationStatus(currentResumeValue);
            }
        }).catch(error => {
            // handle the error
            console.error("fetch payment history failed:", error);
            // If there's an error, ensure loading is turned off
            // Check if the error is due to a JSON parsing issue
            if (error.message.includes('Unexpected token')) {
                console.error('Received non-JSON response:', error);
                // You might want to set a different state here to handle this specific error
            }
            alert(error.message);
        });
    };

    const determineEducationStatus = (currentResumeValue) => {
        // Month mapping
        const monthToNum = {
            "january": 1, "february": 2, "march": 3, "april": 4, "may": 5, "june": 6,
            "july": 7, "august": 8, "september": 9, "october": 10, "november": 11, "december": 12
        };
    
        // Get the current year and month number
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonthNum = currentDate.getMonth() + 1; // getMonth returns 0-based index, so +1
    
        // Initialize education status to N/A
        let educationStatus = '';
    
        // Check if there is education data
        let currentEducation = currentResumeValue?.Resume_info?.education?.[0] || null;
    
        if (currentEducation) {
            // Extract graduation year and month
            let gradYear = currentEducation?.graduation_year ?? -1;
            let gradMonth = currentEducation?.graduation_month ?? -1;
    
            // Convert gradYear to a number if it's a string
            if (typeof gradYear === 'string') {
                gradYear = parseInt(gradYear, 10);
            }
    
            // Convert gradMonth to a number if it's a string (also handle month names)
            if (typeof gradMonth === 'string') {
                if (isNaN(gradMonth)) {
                    // If it's not a number, try to map it as a month name
                    gradMonth = monthToNum[gradMonth.toLowerCase()] || -1;
                } else {
                    gradMonth = parseInt(gradMonth, 10);
                }
            }
    
            // Determine education status
            if (gradYear === -1 || gradMonth === -1) {
                educationStatus = '';
            } else if (currentYear < gradYear || (currentYear === gradYear && currentMonthNum <= gradMonth)) {
                educationStatus = 'in_progress';
            } else {
                educationStatus = 'graduated';
            }
        }
    
        return educationStatus;
    };

      const sendToServer = async () => {
        // Authentication check
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return;
        }
    
        // Job description validation
        if (jobDescription.trim() === '') {
            alert('Please enter a job description before proceeding.');
            return;
        }
    
        // Create FormData
        const formData = new FormData();
        formData.append('resume', JSON.stringify(currentResume));
        formData.append('jobdescription', jobDescription);
        formData.append('jobPosition', jobPosition.trim());
        formData.append('mode', selectedLengthOption);
        formData.append('targetcompany', targetCompany.trim());
    
        // Start loading
        setLoading(true);
        console.log('Send POST request to API, limit 300s');
    
        try {
            const token = await getAccessTokenSilently();
            const response = await axios({
                method: 'post',
                url: '/resumasterapi/revise_resume_new_temp',
                data: formData,
                timeout: 300000, // 5 minutes in milliseconds
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    console.log(`Upload Progress: ${percentCompleted}%`);
                }
            });
    
            setApiResponse(response.data);
            setLoading(false);
    
        } catch (error) {
            setLoading(false);
            console.error('Error details:', error);
    
            if (error.code === 'ECONNABORTED') {
                alert('Request timed out after 5 minutes due to high traffic. Please try again.');
            } else if (error.response) {
                // Server returned an error response (4xx, 5xx)
                alert(`Sorry, there is some issue in our server due to high traffic. Please try again later.\nIMPORTANT: To report the error, please email to admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!`);
            } else if (error.request) {
                // Request was made but no response received
                alert('No response received from server. Please check your connection and try again.');
            } else {
                // Something else went wrong
                alert(`Error: ${error.message}`);
            }
        }
    };

      const sendToServerCoverletter = async () => {
        // Authentication check
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return;
        }
    
        // Create FormData and append all required data
        const formData = new FormData();
        formData.append('first_name', currentResume.Resume_info.basic_info.first_name || '');
        formData.append('last_name', currentResume.Resume_info.basic_info.last_name || '');
        formData.append('email', currentResume.Resume_info.basic_info.email || '');
        formData.append('phone_number', currentResume.Resume_info.basic_info.phone_number || '');
        formData.append('personal_website_url', currentResume.Resume_info.basic_info.personal_website_url || '');
        formData.append('education', education.current);
        formData.append('degree', degree.current);
        formData.append('major', major.current);
        formData.append('education_status', education_status.current);
        formData.append('personal_story', personalStory);
        formData.append('company_name', companyName);
        formData.append('position_title', positionTitle);
        formData.append('company_value', cultureValue);
        formData.append('style', selectedStyleOption);
        formData.append('job_description', coverletterJobDescription);
        formData.append('cl_company_domain', clCompanyDomain);
        formData.append('resume_obj', JSON.stringify(currentResume.Resume_info));
    
        // Start loading
        setCoverletterLoading(true);
        console.log('Send POST request to API, limit 300s');
    
        try {
            const token = await getAccessTokenSilently();
            const response = await axios({
                method: 'post',
                url: '/resumasterapi/revise_coverletter_temp',
                data: formData,
                timeout: 300000, // 5 minutes
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            setCoverletterApiResponse(response.data);
            setCoverletterLoading(false);
    
        } catch (error) {
            setCoverletterLoading(false);
            console.error('Error details:', error);
    
            let errorMessage = 'An error occurred. Please try again.';
    
            if (error.code === 'ECONNABORTED') {
                errorMessage = 'Request timed out after 5 minutes. Please try again.';
            } else if (error.response) {
                switch (error.response.status) {
                    case 400:
                        errorMessage = 'Sorry, there is some issue in the format of uploading files. Please try again later.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!';
                        break;
                    case 500:
                        errorMessage = 'Sorry, there is some issue in our server. Please try again later.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!';
                        break;
                    default:
                        errorMessage = `Server error: ${error.response.data.error || 'Something went wrong'}`;
                }
            } else if (error.request) {
                errorMessage = 'No response received from server. Please check your connection and try again.';
            }
    
            alert(errorMessage);
        }
    };

      // Function to fetch company domains from the backend API
      const fetchCompanyDomains = (query) => {
        fetch(`/resumasterapi/search_relative_domains?q=${query}`)
          .then(response => {
            if (!response.ok) {
              throw new Error(`API call failed with status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            setCompanySuggestions(data);
            setShowDropdown(true);
          })
          .catch(error => {
            console.error('Error fetching company domains:', error);
            setCompanySuggestions([]);
            setShowDropdown(false);
          });
      };

    // Debounced input handling function
  const handleResumeCompanyInputChange = (e) => {
    const newValue = e.target.value;
    setTargetCompany(newValue);

    if (newValue === '') {
        setResumeCompanyDomain('');
    }

    // Clear previous timeout
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    // Set a new timeout to wait for the user to stop typing
    typingTimeoutRef.current = setTimeout(() => {
      if (newValue.trim()) {
        fetchCompanyDomains(newValue); // Call the API after the user stops typing
      }
    }, 600); // Wait for 1 second after the user stops typing
  };

  const handleCLCompanyInputChange = (e) => {
    const newValue = e.target.value;
    setCompanyName(newValue);

    if (newValue === '') {
        setclCompanyDomain('');
    }

    // Clear previous timeout
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    // Set a new timeout to wait for the user to stop typing
    typingTimeoutRef.current = setTimeout(() => {
      if (newValue.trim()) {
        fetchCompanyDomains(newValue); // Call the API after the user stops typing
      }
    }, 600); // Wait for 1 second after the user stops typing
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) && 
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setShowDropdown(false); // Close the dropdown if clicked outside
      }
    };

    // Attach the event listener when dropdown is visible
    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);
    // required filling area
    useEffect(() => {
        if (selectedWorkOption === "resume") {
            if (currentResume && !isEditorEmpty(quillRef) && enoughBalance ) {
                setIsButtonDisabled(false);
            } else {
                setIsButtonDisabled(true);
            }
        } else {
            if (!isEditorEmpty(quillClRef) && companyName.trim() !== '' && positionTitle.trim() !== '' && enoughBalance ) {
                setIsButtonDisabled(false);
            } else {
                setIsButtonDisabled(true);
            }
        }
      }, [selectedWorkOption, enoughBalance, jobDescription, companyName, positionTitle, coverletterJobDescription]);

      if (loading) {
        return <Loading_upload />;
      } else if (apiResponse) {
        return navigate('/new_resume_revision', {
          state: {
            data: apiResponse,
            job_description: jobDescription,
            jobPosition: jobPosition.trim(),
            companyName: targetCompany.trim(),
            companyDomain: resumeCompanyDomain,
          }
        });
      }

      if (coverletterLoading) {
        return <Loading_CL_revise />;
      } else if (coverletterApiResponse) {
        navigate('/ClResults', { state: { data: coverletterApiResponse, jobPosition: positionTitle } });
      }
    

    return (

        <div className="upload-container">
            {/* <Navigation className="navigation-2202" /> */}
            <Helmet>
                {/* <!-- Google tag (gtag.js) --> */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-F812QPTDD9"></script>
                <script>
                    {`window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-F812QPTDD9');
                        `}
                </script>
                <title>Upload - ResuMaster</title>
                <meta property="og:title" content="Upload - ResuMaster" />
            </Helmet>
            <header data-role="Header" className="upload-header">
            </header>
            {
                isAuthenticated ? (
                    <Navigation_loggedin 
                    isCollapsedMenu={false}
                    className="navigation-2202" />
                ) : (
                    <Navigation className="navigation-2202" />
                )
            }
            <button className="dashboard-return-button" onClick={() => navigate('/Dashboard')}>
                <img className="dashboard-button-icon" src="/img/chevron-left.png" alt="icon" />
                <div className="back-button-text">Cancel</div>
            </button>
            <div className="dashboard-upload">
                <div className="dashboard-revision-div upload-box">
                    <div className="title-3">What would you like to work on?</div>
                    <div className="flex-content resume-coverletter-container">
                        <div 
                            className={`card resume-card ${selectedWorkOption === 'resume' ? 'selected' : ''}`}
                            onClick={() => setSelectedWorkOption('resume')}
                        >
                            <div className="get-started-card-header">
                                <img className="dashboard-resume-pic" alt="Resume Revision" 
                                    src={selectedWorkOption === 'resume' ? "/img/getstarted-resume-purple.png" : "/img/getstarted-resume.png"}/>
                                <div className="included-bubble">Cover-letter included</div>
                            </div>

                            <h3 className="card-title" style={{ color: selectedWorkOption === 'resume' ? '#2a2a2a' : '#7A7A7A', fontSize: '30px', fontWeight: '800' }}>Resume</h3>

                            <hr className="card-divider" />
                            
                            <p className="resume-price">100 ResuCredits</p>
                        </div>

                        <div 
                            className={`card cover-letter-card ${selectedWorkOption === 'coverLetter' ? 'selected' : ''}`}
                            onClick={() => setSelectedWorkOption('coverLetter')}
                        >
                            <img className="dashboard-resume-pic" alt="Cover Letter Revision" 
                            src={selectedWorkOption === 'coverLetter' ? "/img/getstarted-cl-purple.png" : "/img/getstarted-cl.png"}/>
                            <h3 className="card-title" style={{ color: selectedWorkOption === 'coverLetter' ? '#2a2a2a' : '#7A7A7A', fontSize: '30px', fontWeight: '800' }}>Cover Letter</h3>

                            <hr className="card-divider" />

                            <p className="resume-price">50 ResuCredits</p>
                        </div>
                    </div>
                </div>
                
                {selectedWorkOption === 'resume' && (
                <div>

                    <div className="dashboard-revision-div">
                        <div className="title-3">Resume</div>
                        <div className="dashboard-revision-box">
                        {dbResumeInfo && dbResumeInfo.Record_exist && currentResume && currentResume.Resume_name ? (
                                currentResume.Resume_name
                            ) : (
                                'No resume available, please return to the dashboard to upload your resume.'
                            )}
                        </div>
                    </div>

                    <div className="dashboard-revision-div">
                        <div className="taget-company-input-title">
                            <div className="title-3">Target Company</div>
                            <a href="https://logo.dev" alt="Logo API"> Logos provided by Logo.dev</a>
                        </div>      
                        <div style={{ position: 'relative', width: '100%' }}>
                            {/* Conditionally render the logo if resumeCompanyDomain is set */}
                            {resumeCompanyDomain && (
                            <img
                                src={resumeCompanyDomain}
                                alt={targetCompany}
                                style={{
                                position: 'absolute',
                                left: '10px', // Position inside the input
                                top: '50%',
                                transform: 'translateY(-50%)', // Vertically center the image
                                height: '25px', // Adjust height to fit inside input
                                width: '25px',  // Ensure width is proportionate
                                zIndex: 1, // Make sure the image is on top
                                }}
                            />
                            )}
                            <input
                            ref={inputRef}
                            value={targetCompany}
                            placeholder="e.g. Google"
                            onChange={handleResumeCompanyInputChange} // Using the debounced change handler
                            className="company-container"
                            style={{
                                paddingLeft: resumeCompanyDomain ? '40px' : '10px', // Space for the image
                                width: '100%', // Ensure input takes full width of the parent div
                                boxSizing: 'border-box', // Ensure padding is included in width calculation
                                zIndex: 0, // Ensure input is behind the image
                            }}
                            />
                        </div>

                        {showDropdown && companySuggestions.length > 0 && (
                            <div className="styled-dropdown-container" ref={dropdownRef}>
                            {companySuggestions.map((company, index) => (
                                <div
                                key={index}
                                className="styled-dropdown-item"
                                onClick={() => {
                                    // Set the company name and logo when the dropdown item is clicked
                                    setTargetCompany(company.name);
                                    setResumeCompanyDomain(company.logo_url);
                                    setShowDropdown(false); // Optionally close the dropdown after selection
                                }}
                                >
                                <div className="dropdown-logo">
                                    <img src={company.logo_url} alt={company.name} />
                                </div>
                                <div className="dropdown-content">
                                    <span className="company-name">{company.name}</span>
                                    <span className="company-domain">{company.domain}</span>
                                </div>
                                </div>
                            ))}
                            </div>
                        )}
                        </div>

                    <div className="dashboard-revision-div">
                        <div className="title-3">Target Position</div>
                        <input
                        value={jobPosition}
                        placeholder='e.g. Software Engineer'
                        onChange={e => setJobposition(e.target.value)}
                        className="company-container"
                        ></input>
                    </div>

                    <div className="dashboard-revision-div">
                        <div className="title-3">Job Description <span style={{ color: '#B00000' }}>*</span></div>
                        {/* Quill Editor */}
                        <ReactQuill
                            ref={quillRef}
                            value={jobDescription}
                            onChange={handleQuillChange}
                            placeholder="Copy and paste the qualification section of the job description"
                            modules={{
                                toolbar: [
                                [{list: 'ordered'}, {list: 'bullet'}]
                                ]
                            }}
                            className="custom-quill-editor"
                        />
                    </div>

                    <div className="dashboard-revision-div">
                        <div className="title-3">Experience Length</div>
                        <div className="company-caption">
                            {/* Select your experience length:
                            <div><strong>Concise Mode:</strong> Delivers succinct bullet points, limit the resume to 1 page for 3-4 experiences.</div>
                            <div><strong>Standard Mode:</strong> Offers a balanced mix of quality and brevity.</div>
                            <div><strong>Creative Mode:</strong> Generates extended content for innovative resume writing.</div> */}
                            Please select the preferred length for your revised experience content
                        </div>

                        <div className='option-box'>
                            <div className={`option-button ${selectedLengthOption === "Concise" ? 'selected' : ''}`} onClick={() => setSelectedLengthOption("Concise")}>Concise</div>
                            <div className={`option-button ${selectedLengthOption === "Standard" ? 'selected' : ''}`} onClick={() => setSelectedLengthOption("Standard")}>Standard</div>
                            <div className={`option-button ${selectedLengthOption === "Creative" ? 'selected' : ''}`} onClick={() => setSelectedLengthOption("Creative")}>Creative</div>
                        </div>
                    </div>
                    <div className="button-upload-section">
                        <div className="frame-10">
                            <Button
                                className="dashboard-upload-button"
                                onClick={sendToServer}
                                disabled={isButtonDisabled}
                                text="Continue"
                            />
                        </div>
                    </div>
                    {enoughBalance ? (
                        <div className="sufficient-balance">
                            By clicking 'Continue', {selectedWorkOption === "resume" ? 100 : 50} ResuCredits will be deducted from your balance.
                        </div>
                    ) : (
                        <div className="insufficient-balance">
                            <Link to='/plan'>
                                You do not have enough ResuCredits, please purchase here
                            </Link>
                        </div>
                    )
                    }
                    <div className="spacer" style={{ height: '20px' }}></div>
                </div>)}

                {selectedWorkOption === 'coverLetter' && (
                <div>
                    <Form>

                        <div className="dashboard-revision-div">
                            <div className="title-3">Resume</div>
                            <div className="dashboard-revision-box">
                                {dbResumeInfo && dbResumeInfo.Record_exist && currentResume && currentResume.Resume_name ? (
                                    currentResume.Resume_name
                                ) : (
                                    'No resume available, please return to the dashboard to upload your resume.'
                                )}
                            </div>
                        </div>

                        <Form.Group className="dashboard-revision-div">
                            <Form.Label className='form-title'>Job Description <span style={{ color: '#B00000' }}>*</span></Form.Label>
                            
                            {/* Quill Editor */}
                            <ReactQuill
                                ref={quillClRef}
                                value={coverletterJobDescription}
                                onChange={handleQuillChangeCL}
                                placeholder="Copy and paste the qualification section of the job description"
                                modules={{
                                    toolbar: [
                                    [{list: 'ordered'}, {list: 'bullet'}],
                                    ]
                                }}
                                className="custom-quill-editor" // Add your custom class here
                                />
                        </Form.Group>


                        <div className="dashboard-revision-div">
                            <div className='taget-company-input-title'>
                                <div className="title-3">Target Company <span style={{ color: '#B00000' }}>*</span></div>
                                <a href="https://logo.dev" alt="Logo API"> Logos provided by Logo.dev</a>
                            </div>

                            <div style={{ position: 'relative', width: '100%' }}>
                                {/* Conditionally render the logo if resumeCompanyDomain is set */}
                                {clCompanyDomain && (
                                <img
                                    src={clCompanyDomain}
                                    alt={companyName}
                                    style={{
                                    position: 'absolute',
                                    left: '10px', // Position inside the input
                                    top: '50%',
                                    transform: 'translateY(-50%)', // Vertically center the image
                                    height: '25px', // Adjust height to fit inside input
                                    width: '25px',  // Ensure width is proportionate
                                    zIndex: 1, // Make sure the image is on top
                                    }}
                                />
                                )}
                                <input
                                ref={inputRef}
                                value={companyName}
                                placeholder="e.g. Google"
                                onChange={handleCLCompanyInputChange} // Using the debounced change handler
                                className="company-container"
                                style={{
                                    paddingLeft: clCompanyDomain ? '40px' : '10px', // Space for the image
                                    width: '100%', // Ensure input takes full width of the parent div
                                    boxSizing: 'border-box', // Ensure padding is included in width calculation
                                    zIndex: 0, // Ensure input is behind the image
                                }}
                                />
                            </div>

                            {showDropdown && companySuggestions.length > 0 && (
                                <div className="styled-dropdown-container" ref={dropdownRef}>
                                {companySuggestions.map((company, index) => (
                                    <div
                                    key={index}
                                    className="styled-dropdown-item"
                                    onClick={() => {
                                        // Set the company name and logo when the dropdown item is clicked
                                        setCompanyName(company.name);
                                        setclCompanyDomain(company.logo_url);
                                        setShowDropdown(false); // Optionally close the dropdown after selection
                                    }}
                                    >
                                    <div className="dropdown-logo">
                                        <img src={company.logo_url} alt={company.name} />
                                    </div>
                                    <div className="dropdown-content">
                                        <span className="company-name">{company.name}</span>
                                        <span className="company-domain">{company.domain}</span>
                                    </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        </div>

                        <Form.Group className="dashboard-revision-div">
                            <Form.Label className='form-title'>Target Position <span style={{ color: '#B00000' }}>*</span></Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="e.g. Software Engineer"
                                defaultValue={positionTitle} 
                                onChange={e => setPositionTitle(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="dashboard-revision-div">
                            <Form.Label className='form-title'>Last Attended School</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="" 
                                defaultValue={education.current} 
                                onChange={e => education.current = e.target.value} />
                        </Form.Group>

                        <Form.Group className="dashboard-revision-div">
                            <Form.Label className='form-title'>Degree</Form.Label>
                            <Form.Control
                                type="text" 
                                placeholder="" 
                                defaultValue={degree.current} 
                                onChange={e => degree.current = e.target.value} />
                        </Form.Group>

                        <Form.Group className="dashboard-revision-div">
                            <Form.Label className='form-title'>Major</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="" 
                                defaultValue={major.current} 
                                onChange={e => major.current = e.target.value} />
                        </Form.Group>

                        <Form.Group className="dashboard-revision-div">
                            <Form.Label className='form-title'>Are you currently in school</Form.Label>
                            <Form.Select 
                                className="mb-3 form-box" 
                                defaultValue={education_status.current} 
                                onChange={e => education_status.current = e.target.value}>
                                <option value="" disabled>Select from the following options</option>
                                <option value="in_progress">Yes</option>
                                <option value="graduated">No</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="dashboard-revision-div">
                            <Form.Label className='form-title'>Cover Letter Writing Style</Form.Label>
                            <div className='option-box'>
                                <div className={`option-button ${selectedStyleOption === "Modern" ? 'selected' : ''}`} onClick={() => setSelectedStyleOption("Modern")}>Modern</div>
                                <div className={`option-button ${selectedStyleOption === "Creative" ? 'selected' : ''}`} onClick={() => setSelectedStyleOption("Creative")}>Creative</div>
                            </div>
                        </Form.Group>

                        <Form.Group className="dashboard-revision-div">
                            <Form.Label className='form-title'>Company Value</Form.Label>
                            <div className='description-box'>
                                <span className="description-text">Please tell us about the companys culture and value (Limited to 100 Characters)</span>
                                <span className="description-text counter-text">{`${cultureValue.length}/100`}</span>
                            </div>
                            <Form.Control as="textarea" rows={5} value={cultureValue} onChange={e => setCultureValue(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="dashboard-revision-div">
                            <Form.Label className='form-title'>Personal Story</Form.Label>
                            <div className='description-box'>
                                <span className="description-text">Please tell us about a personal story that we can mention in your cover letter (Limited to 1000 Characters)</span>
                                <span className="description-text counter-text">{`${personalStory.length}/1000`}</span>
                            </div>
                            <Form.Control as="textarea" rows={5} value={personalStory} onChange={e => setPersonalStory(e.target.value)} />
                        </Form.Group>
                    </Form>
                <div className="button-upload-section">
                    <div className="frame-10">
                        <Button
                            className="dashboard-upload-button"
                            onClick={sendToServerCoverletter}
                            disabled={isButtonDisabled}
                            text="Continue"
                        />
                    </div>
                </div>
                {enoughBalance ? (
                    <div className="sufficient-balance">
                        By clicking 'Continue', {selectedWorkOption === "resume" ? 100 : 50} ResuCredits will be deducted from your balance.
                    </div>
                ) : (
                    <div className="insufficient-balance">
                        <Link to='/plan'>
                            You do not have enough ResuCredits, please purchase here
                        </Link>
                    </div>
                )
                }
                <div className="spacer" style={{ height: '20px' }}></div>
                </div>)}
            </div>
            <MobileWarning />
        </div>

    )
}
