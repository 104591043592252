import React, { useEffect, useCallback, useState } from 'react'
import { Navigation } from "../../components/Navigation";
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
import { Helmet } from 'react-helmet';
import { useAuth0 } from "@auth0/auth0-react";  // <-- Import useAuth0 hook
import { useNavigate } from 'react-router-dom';
import { Loading_mainpage } from '../../screens/Loading';
import Footer from "../../components/footer/footer";
import "./style.css";

export const Plan = () => {
    //---------------------------------auhentication code----------------------------------------
  const { isAuthenticated, loginWithRedirect, logout, user, isLoading, getAccessTokenSilently } = useAuth0();
  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!isAuthenticated) {
    return <div>Please log in to view your revision history.</div>;
  }
  //---------------------------------auhentication code----------------------------------------

  useEffect(() => {
    fetchSubscriptionStatus();
  }, []);

  const [loading, setLoading] = useState(false); // state for loading
  const [check_register, setcheck_register] = useState(false); // state for loading
  const [isSubscribed, setIsSubscribed] = useState(false);
  const navigate = useNavigate();
  const handlePlanSelect = (id) => {
    navigate(`/payment/${id}`);
  }

  const fetchSubscriptionStatus = async () => {
    // send a POST request
    //---------------------------------auhentication code----------------------------------------
    //get user's specific token
    const token = await getAccessTokenSilently();
    //---------------------------------auhentication code----------------------------------------
    fetch('/resumasterapi/check_user_status', { //api endpoint
      method: 'POST',
      //---------------------------------auhentication code----------------------------------------
      headers: {
        Authorization: `Bearer ${token}`
      },
    //---------------------------------auhentication code----------------------------------------
    }).then(response => {
      // Check for the 400 status code 
      // console.log('Server Response:', response);
      if (response.status === 400) {
        throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
      }
      if (response.status == 500) {
        throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
      }
      //---------------------------------auhentication code----------------------------------------
      if (response.status == 401) {
        throw new Error('Sorry, you must login first')
      }
      //---------------------------------auhentication code----------------------------------------
      return response.json();
    }).then(data => {
    //   console.log(isSubscribed);
      if (data.in_subscription === true){
        setIsSubscribed(true);
        // console.log(isSubscribed);
      }
    }).catch(error => {
      // handle the error
      console.error("fetch user subscription status failed:", error);
      // If there's an error, ensure loading is turned off
      // Check if the error is due to a JSON parsing issue
      if (error.message.includes('Unexpected token')) {
        console.error('Received non-JSON response:', error);
        // You might want to set a different state here to handle this specific error
    }
      //setLoading(false);
      alert(error.message);  // Displaying the error message to the user
    });
  }
  //for 3000 resucredits for now, previously for monthly subscription
  const handlecheckout = async () => {
    //---------------------------------auhentication code----------------------------------------
    if (!isAuthenticated) {
        alert("Login required: Please login first");
        return
    }
    //---------------------------------auhentication code----------------------------------------
    //start loading
    // send a POST request
    //---------------------------------auhentication code----------------------------------------
    //get user's specific token
    const token = await getAccessTokenSilently();
    //---------------------------------auhentication code----------------------------------------
    fetch('/resumasterapi/create-one-time-checkout-session-a', { //api endpoint
      method: 'POST',
      //---------------------------------auhentication code----------------------------------------
      headers: {
        Authorization: `Bearer ${token}`
      },
      //---------------------------------auhentication code----------------------------------------
    }).then(response => {
      // Check for the 400 status code 
      // console.log('Server Response:', response);
      if (response.status === 400) {
        throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
      }
      if (response.status == 500) {
        throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
      }
      //---------------------------------auhentication code----------------------------------------
      if (response.status == 401) {
        throw new Error('Sorry, you must login first')
      }
      //---------------------------------auhentication code----------------------------------------
      return response.json();
    }).then(data => {
      window.location.href = data.url;
    }).catch(error => {
      // handle the error
      console.error(error);
      // If there's an error, ensure loading is turned off
      alert(error.message);  // Displaying the error message to the user
    });
  }

  //for 1000 resucredits
  const handlecheckout_a = async () => {
    //---------------------------------auhentication code----------------------------------------
    if (!isAuthenticated) {
        alert("Login required: Please login first");
        return
    }
    //---------------------------------auhentication code----------------------------------------
    //start loading
    // send a POST request
    //---------------------------------auhentication code----------------------------------------
    //get user's specific token
    const token = await getAccessTokenSilently();
    //---------------------------------auhentication code----------------------------------------
    fetch('/resumasterapi/create-one-time-checkout-session-b', { //api endpoint
      method: 'POST',
      //---------------------------------auhentication code----------------------------------------
      headers: {
        Authorization: `Bearer ${token}`
      },
      //---------------------------------auhentication code----------------------------------------
    }).then(response => {
      // Check for the 400 status code 
      // console.log('Server Response:', response);
      if (response.status === 400) {
        throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
      }
      if (response.status == 500) {
        throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
      }
      //---------------------------------auhentication code----------------------------------------
      if (response.status == 401) {
        throw new Error('Sorry, you must login first')
      }
      //---------------------------------auhentication code----------------------------------------
      return response.json();
    }).then(data => {
      window.location.href = data.url;
    }).catch(error => {
      // handle the error
      console.error(error);
      // If there's an error, ensure loading is turned off
      alert(error.message);  // Displaying the error message to the user
    });
  }

  //for 200 resucredits
  const handlecheckout_b = async () => {
    //---------------------------------auhentication code----------------------------------------
    if (!isAuthenticated) {
        alert("Login required: Please login first");
        return
    }
    //---------------------------------auhentication code----------------------------------------
    //start loading
    // send a POST request
    //---------------------------------auhentication code----------------------------------------
    //get user's specific token
    const token = await getAccessTokenSilently();
    //---------------------------------auhentication code----------------------------------------
    fetch('/resumasterapi/create-one-time-checkout-session-d', { //api endpoint
      method: 'POST',
      //---------------------------------auhentication code----------------------------------------
      headers: {
        Authorization: `Bearer ${token}`
      },
      //---------------------------------auhentication code----------------------------------------
    }).then(response => {
      // Check for the 400 status code 
      // console.log('Server Response:', response);
      if (response.status === 400) {
        throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
      }
      if (response.status == 500) {
        throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
      }
      //---------------------------------auhentication code----------------------------------------
      if (response.status == 401) {
        throw new Error('Sorry, you must login first')
      }
      //---------------------------------auhentication code----------------------------------------
      return response.json();
    }).then(data => {
      window.location.href = data.url;
    }).catch(error => {
      // handle the error
      console.error(error);
      // If there's an error, ensure loading is turned off
      alert(error.message);  // Displaying the error message to the user
    });
  }

  //for Monthly subscription
  const handlecheckout_sub = async () => {
    //---------------------------------auhentication code----------------------------------------
    if (!isAuthenticated) {
        alert("Login required: Please login first");
        return
    }
    //---------------------------------auhentication code----------------------------------------
    //start loading
    // send a POST request
    //---------------------------------auhentication code----------------------------------------
    //get user's specific token
    const token = await getAccessTokenSilently();
    //---------------------------------auhentication code----------------------------------------
    fetch('/resumasterapi/create-checkout-session', { //api endpoint
      method: 'POST',
      //---------------------------------auhentication code----------------------------------------
      headers: {
        Authorization: `Bearer ${token}`
      },
      //---------------------------------auhentication code----------------------------------------
    }).then(response => {
      // Check for the 400 status code 
      // console.log('Server Response:', response);
      if (response.status === 400) {
        throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
      }
      if (response.status == 500) {
        throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
      }
      //---------------------------------auhentication code----------------------------------------
      if (response.status == 401) {
        throw new Error('Sorry, you must login first')
      }
      //---------------------------------auhentication code----------------------------------------
      return response.json();
    }).then(data => {
      window.location.href = data.url;
    }).catch(error => {
      // handle the error
      console.error(error);
      // If there's an error, ensure loading is turned off
      alert(error.message);  // Displaying the error message to the user
    });
  }
  

  if (loading) {
    return <Loading_mainpage />;
  }
  
  return(
    <div className="pricing">
        <Helmet>
          {/* <!-- Google tag (gtag.js) --> */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-F812QPTDD9"></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-F812QPTDD9');
          `}
        </script>
        <title>Pricing&Plan - ResuMaster</title>
        <meta property="og:title" content="Pricing&Plan - ResuMaster" />
        </Helmet>
        {
        isAuthenticated ? (
            <Navigation_loggedin 
            isCollapsedMenu={false}
            isShowDiscord={true}
            className="navigation-2202" />
          ) : (
            <Navigation className="navigation-2202" />
          )
        }
        
        <div className="pricing-title">
            <div className="title-heading">Ready to Stand Out This Job Hunting Season?</div>
            <div className="title-label">Enjoy an <strong>Exclusive 40% Off Offer</strong>!
            Don't miss out—ends February 29</div>
            <div className="title-sublabel">1 resume revision = 100 ResuCredits</div>
            <div className="title-sublabel">1 cover letter revision = 50 ResuCredits</div>
        </div>
        <div className="pricingcard-container">
            <div className="pricingcard-top">
                <div className="pricecard-top-content">
                    <div className="left-side-content">
                        <div className="card-tag">Exclusive 40% Off</div>
                        <div className="card-name">Monthly Subscription</div>
                        <div className="card-price">
                            <span className="new-price">$27.59</span>
                            <span className="old-price">$45.99</span>
                        </div>
                        <div className="price-comment">then, renews at $45.99/month after</div>
                    </div>
                    <div className="right-side-content">
                        <div className="plan-include">
                            <img className="checkmark-icon" src='/img/checkmark-gray.png' />
                            <div className="plan-include-content">Ideal for active job seekers</div>
                        </div>
                        <div className="plan-include">
                            <img className="checkmark-icon" src='/img/checkmark-gray.png' />
                            <div className="plan-include-content">Unlimited access to resume revision</div>
                        </div>
                        <div className="plan-include">
                            <img className="checkmark-icon" src='/img/checkmark-gray.png' />
                            <div className="plan-include-content">Unlimited access to cover letter builder</div>
                        </div>
                        <div className="plan-include">
                            <img className="checkmark-icon" src='/img/checkmark-gray.png' />
                            <div className="plan-include-content">Cancel anytime</div>
                        </div>
                    </div>
                </div>
                <div className="subscribe-button-container">
                  {/* {isSubscribed ? (
                      <button className="subscribe-button-subscribed">You are Subscribed</button>
                  ) : (
                      <button className="subscribe-button" onClick={handlecheckout}>Upgrade Now</button>
                  )} */}
                    <button className="subscribe-button" onClick={handlecheckout_sub}>Purchase</button>
                </div>
            </div>
            
            <div className="pricingcard-bottom">
                <div className="bottom-card-3">
                    <div className="card-tag">Exclusive 40% Off</div>
                    <div className="bottomcard-name">3000 ResuCredits</div>
                    <div className="card-price">
                            <span className="new-price">$23.99</span>
                            <span className="old-price">$44.99</span>
                    </div>
                    {/* <div className="price-comment">Laid-off recently or need specific help </div> */}
                    <div>
                        <div className="bottom-plan-include">
                                <img className="checkmark-icon" src='/img/checkmark-gray.png' />
                                <div className="plan-include-content">Best value for career switchers </div>
                        </div>
                        <div className="bottom-plan-include">
                                <img className="checkmark-icon" src='/img/checkmark-gray.png' />
                                <div className="plan-include-content">ResuCredits never expire </div>
                        </div>
                    </div>
                    <div className="subscribe-button-container">
                        <button className="subscribe-button-small" onClick={handlecheckout}>Purchase</button>
                    </div>
                    
                </div>
                <div className="bottom-card-2">
                    <div className="bottomcard-name">1000 ResuCredits</div>
                    <div className="card-price">
                            <span className="new-price">$14.99</span>
                            <span className="old-price">$19.99</span>
                    </div>
                    {/* <div className="price-comment">$0.99 / token </div> */}
                    <div>
                        <div className="bottom-plan-include">
                                <img className="checkmark-icon" src='/img/checkmark-gray.png' />
                                <div className="plan-include-content">Suitable for moderate resume adjustments </div>
                        </div>
                        <div className="bottom-plan-include">
                                <img className="checkmark-icon" src='/img/checkmark-gray.png' />
                                <div className="plan-include-content">ResuCredits never expire </div>
                        </div>
                    </div>
                    <div className="subscribe-button-container">
                        <button className="subscribe-button-small" onClick={handlecheckout_a}>Purchase</button>
                    </div>
                    
                </div>
                <div className="bottom-card-1">
                    <div className="bottomcard-name">200 ResuCredits</div>
                    <div className="card-price">
                            <span className="new-price">$4.99</span>
                            <span className="old-price">$5.99</span>
                    </div>
                    {/* <div className="price-comment">$1.49 / token </div> */}
                    <div>
                        <div className="bottom-plan-include">
                                <img className="checkmark-icon" src='/img/checkmark-gray.png' />
                                <div className="plan-include-content">Best for minimal resume updates and tweaks </div>
                        </div>
                        <div className="bottom-plan-include">
                                <img className="checkmark-icon" src='/img/checkmark-gray.png' />
                                <div className="plan-include-content">ResuCredits never expire </div>
                        </div>
                    </div>
                    <div className="subscribe-button-container">
                        <button className="subscribe-button-small" onClick={handlecheckout_b}>Purchase</button>
                    </div>
                    
                </div>
            </div>

        </div>
        <div className="foot-box">
            <Footer />
        </div>

    </div>
  )
}