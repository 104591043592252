import React, { useState, useRef, useEffect, useMemo } from 'react';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import './ResumeModal.css';

const ResumeModal = ({ isOpen, onClose, resumeData }) => {
    if (!isOpen) return null;
    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton, Zoom } = zoomPluginInstance;
	const dropdownRef = useRef(null);
    const [pdfUrl, setPdfUrl] = useState('');
    const [pdfBlob, setPdfBlob] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(resumeData.templateIndex); // Set default selected template to 0
    const showBoldKeywords = resumeData.isShowBoldKeywords;
    const combinedSkills = resumeData.boldKeywordsList;

    const monthMap = {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
    };

	const handleClose = (e) => {
		e.stopPropagation();
		onClose();
	};
    
    // for data get from the database
    const executive_summary_DB = resumeData.executiveSummary;
    const data_DB = resumeData;

  // for real dynamic data
    const [exp_data, setExpData] = useState(data_DB.expData);
    const [original_data, setOriginalData] = useState(data_DB.originalData);
    const [executive_summary, setExecutiveSummary] = useState(executive_summary_DB);
    const [isSkillsCategorized, setIsSkillsCategorized] = useState(original_data.isSkillsCategorized);
    const [categorizedSkills, setCategorizedSkills] = useState(original_data.categorizedSkills);
    // console.log(isSkillsCategorized)
    // console.log(categorizedSkills)
    // console.log(original_data);
    // console.log(executive_summary);
    // console.log(exp_data);

    //info locally needed - original_data info section
    const personal_infor ={
        'First Name': original_data.personal_info.first_name,
        'Last Name': original_data.personal_info.last_name,
        'Email Address': original_data.personal_info.email,
        'Phone Number': original_data.personal_info.phone_number,
        'Location': original_data.personal_info.location,
        'LinkedIn/Personal Website URL':original_data.personal_info.personal_website_url
    }
    const skills=original_data.skills
    const certificates = original_data.certificate;
    const education = original_data.education

    // Split data into work experience and project experience
    const work_experience = exp_data.work;
    const project_experience = exp_data.project;


    //important info for resume rendering
    const [data, setData] = useState({
        personal_info: personal_infor,
        executive_summary: executive_summary,
        skills: skills,
        education: education,
        certificate: certificates,
        revised_work_exp: work_experience,
        revised_proj_exp: project_experience
    });

  const name = (data.personal_info['First Name'] || '') + " " + (data.personal_info['Last Name'] || '');
  const email = data.personal_info['Email Address'] || '';
  const phone_number = data.personal_info['Phone Number'] || '';
  const personal_website = data.personal_info['LinkedIn/Personal Website URL'] || '';
  const user_location = data.personal_info['Location'] || '';
  const summary = data.executive_summary;
  const education_list = data.education;
  const revisedWorkExp = data.revised_work_exp ?? [];
  const revisedProjExp = data.revised_proj_exp ?? [];
  const experiences = [...revisedWorkExp, ...revisedProjExp];
  

  const buildInitialSectionOrder = (template) => {
    const sections = [];
    
    // Summary 总是添加在最前面
    if (summary) {
      sections.push('Summary');
    }
    
    // Education
    if (education_list && education_list.length > 0) {
      sections.push('Education');
    }
    
    if (template === 0) {
      // Experience
      if (experiences && experiences.length > 0) {
          sections.push('Experience');
      }
    } else if (template === 1) {
      // Work Experience
      if (revisedWorkExp && revisedWorkExp.length > 0) {
        sections.push('Work Experience');
      }
      
      // Project Experience
      if (revisedProjExp && revisedProjExp.length > 0) {
        sections.push('Project Experience');
      }
    }
    
    if (skills && skills.length > 0) {
      sections.push('Skills');
    }

    if (certificates && certificates.length > 0) {
      sections.push('Certificates');
    }
    
    return sections;
  };

  console.log(data_DB?.sectionOrder);
  const sectionOrder = data_DB?.sectionOrder ?? buildInitialSectionOrder(selectedTemplate);

  // toolbar section
  const [fontsize, setFontSize] = useState(
    resumeData.fontsize != null ? resumeData.fontsize : 11
  );
  const [name_fontsize, setNameFontSize] = useState(
    resumeData.name_fontsize != null ? resumeData.name_fontsize : 14
  );
  const [sectionSpacing, setSectionSpacing] = useState(
    resumeData.sectionSpacing != null ? resumeData.sectionSpacing : 20
  );
  const [sectionBetweenLine, setSectionBetweenLine] = useState(
    resumeData.sectionBetweenLine != null ? resumeData.sectionBetweenLine : 5
  );
  const [lineHeightSpacing, setLineHeightSpacing] = useState(
    resumeData.lineHeightSpacing != null ? resumeData.lineHeightSpacing : fontsize
  );
  // template 1 special toolbar variables
  const [template_1_section_header, setTemplate1SectionHeader] = useState(
    resumeData.template_1_section_header != null ? resumeData.template_1_section_header : 13
  );
  // template 3 special toolbar variables
  const [name_fontsize_template_3, setNameFontsizeTemplate3] = useState(
    resumeData.name_fontsize_template_3 != null ? resumeData.name_fontsize_template_3 : 25
  );

  const generateTemplate1 = async (pdfDoc, timesRomanFont, timesRomanBoldFont) => {
    const createPage = () => {
      const page = pdfDoc.addPage([595, 842]); // A4 size page dimensions
      return page;
    };

    let page = createPage();
    let { width, height } = page.getSize();
    let yPosition = height - 40;

    const drawText = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
      const textToDraw = text || '';
      // Ensure text is not null or undefined
      const sanitizedText = textToDraw
              .replace(/，/g, ',')          // Replace full-width comma with a regular comma
              .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
              .replace(/[•●⚫⬤∙]\s*/g, '• ')
              .trim();
  
      if (y < 50) {
          page = createPage();
          y = height - 50;
      }
      page.drawText(sanitizedText, { x, y, size, font, color });
      return y;
    };

    const drawSectionHeader = (text, y) => {
      y = drawText(text, 50, y, template_1_section_header, timesRomanBoldFont, rgb(0, 0, 0));
      return y - lineHeightSpacing - 4;
    };
      
    const drawBulletedTextNoBold = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0), maxWidth = width - 100) => {
      const sanitizedText = text
          .replace(/[\r\n]+/g, ' ')
          .replace(/\s+/g, ' ')
          .replace(/，/g, ',')
          .replace(/‐|–|—/g, '-')
          .replace(/[•●⚫⬤∙]\s*/g, '• ')
          .trim();
      const bullet = '• ';
      const bulletWidth = font.widthOfTextAtSize(bullet, size);
      const indent = x + bulletWidth; // Position for the subsequent lines
      const words = sanitizedText.split(' ');
      let line = '';
      let firstLine = true;

      words.forEach(word => {
        const testLine = line + word + ' ';
        const testLineWidth = font.widthOfTextAtSize((firstLine ? bullet : '') + testLine, size);

        if (testLineWidth < maxWidth) {
          line = testLine;
        } else {
          y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
          y -= lineHeightSpacing + 4;
          line = word + ' ';
          firstLine = false;
        }
      });

      y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
      return y - lineHeightSpacing - 4;
    };

    const normalizeText = (text) => {
      if (!text) return '';
      return text.toLowerCase()
        .replace(/[-](?=\w)/g, ' ')
        .replace(/\.(?=[A-Za-z])/g, ' ')
        .replace(/[,!?\.]$/g, '')
        .replace(/[,!?]/g, '')
        .replace(/[•●⚫⬤∙]\s*/g, '• ')
        .trim();
    };
        
    const drawBulletedText = (text, x, y, size = fontsize, regularFont = timesRomanFont, boldFont = timesRomanBoldFont, color = rgb(0, 0, 0), maxWidth = width - 100) => {
      if (!text) return y;
    
      // Sanitize and normalize the input text
      const sanitizedText = text
        .replace(/[\r\n]+/g, ' ')
        .replace(/\s+/g, ' ')
        .replace(/，/g, ',')
        .replace(/‐|–|—/g, '-')
        .replace(/[•●⚫⬤∙]\s*/g, '• ')
        .trim();
    
      // Special markers for bold text
      const BOLD_START = ' [[BOLD]] '; // Using string markers instead
      const BOLD_END = ' [[/BOLD]] ';
    
      // First pass: Mark all keywords in text
      let processedText = sanitizedText;
    
      combinedSkills.forEach(keyword => {
        if (!keyword) return;
        
        const normalizedKeyword = normalizeText(keyword);
        
        // Handle multi-word keywords
        if (normalizedKeyword.includes(' ')) {
          const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
          const regexPattern = escapedKeyword
              .split(' ')
              .join('(?:[-. ]|(?= ))');
          const keywordRegex = new RegExp(`\\b${regexPattern}\\b`, 'gi');
          
          // 修改这里：先找到匹配的关键词，然后检查后面的标点符号
          processedText = processedText.replace(keywordRegex, match => {
              // 分离词和标点符号
              const punctMatch = match.match(/^(.*?)([\s,，.。!！?？]*)$/);
              if (punctMatch) {
                  const [, word, punct] = punctMatch;
                  return `${BOLD_START}${word}${BOLD_END}${punct}`;
              }
              return `${BOLD_START}${match}${BOLD_END}`;
          });
        } else {
          // Handle single-word keywords
          const words = processedText.split(/(\s+)/);
          processedText = words.map(word => {
            // 分离词和标点符号
            const punctMatch = word.match(/^(.*?)([\s,，.。!！?？]*)$/);
            if (punctMatch) {
              const [, mainWord, punct] = punctMatch;
              if (normalizeText(mainWord) === normalizedKeyword) {
                return `${BOLD_START}${mainWord}${BOLD_END}${punct}`;
              }
            }
            return word;
          }).join('');
      }
    });
    
      // Drawing parameters
      const bullet = '• ';
      const bulletWidth = regularFont.widthOfTextAtSize(bullet, size);
      const indent = x + bulletWidth;
      const words = processedText.split(' ').filter(word => word.length > 0);
      let line = '';
      let firstLine = true;
  
    
      let boldStart = false;
      let lastLineWidth = firstLine ? regularFont.widthOfTextAtSize(bullet, size) : 0;
      words.forEach(word => {
        if (word === BOLD_START.trim()) {
            boldStart = true;
            // 移除加粗开始标记
            word = word.replace(BOLD_START, '');
        }
        
        // 检查是否包含加粗结束标记
        if (word === BOLD_END.trim()) {
            boldStart = false;
            // 移除加粗结束标记
            word = word.replace(BOLD_END, '');
        }
    
        // 根据当前状态选择字体
        const currentFont = boldStart ? boldFont : regularFont;
    
        const wordWidth = (word === BOLD_START.trim() || word === BOLD_END.trim()) 
        ? 0 
        : currentFont.widthOfTextAtSize(word + ' ', size);
        const testLineWidth = lastLineWidth + wordWidth;
    
        const isPunctuation = /^[,.!?;:""''()\[\]<>]+$/.test(word);
    
        if (testLineWidth < maxWidth || isPunctuation || 
          word === BOLD_START.trim() || 
          word === BOLD_END.trim()) {
            // 保存原始词，包括其当前的加粗状态
            line = line + word + ' ';
            lastLineWidth = testLineWidth;
        } else {
            // 换行并渲染
            y = drawTextWithStyles((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, regularFont, boldFont, color, BOLD_START, BOLD_END);
            y -= lineHeightSpacing + 4;
            line = word + ' ';
            firstLine = false;
            lastLineWidth = regularFont.widthOfTextAtSize(word + ' ', size);
        }
      });
    
      // Draw the last line
      y = drawTextWithStyles((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, regularFont, boldFont, color, BOLD_START, BOLD_END);
      return y - lineHeightSpacing - 4;
    };
          
    let drawBoldStart = false;
    
    const drawTextWithStyles = (line, x, y, size, regularFont, boldFont, color, BOLD_START, BOLD_END) => {
      let currentX = x;
      const words = line.split(' ');
      let skipNextSpace = false;  // 添加标志来控制是否跳过下一个空格
      
      words.forEach((word, index) => {
          // 跳过空词
          if (!word) return;
          
          // 检查是否是加粗结束标记
          if (word === BOLD_END.trim()) {
              drawBoldStart = false;
              // 如果下一个词是标点符号，设置标志
              if (index < words.length - 1 && /^[,，.。!！?？]/.test(words[index + 1])) {
                  skipNextSpace = true;
              }
              return;
          }
          
          // 检查是否是加粗开始标记
          if (word === BOLD_START.trim()) {
              drawBoldStart = true;
              return;
          }
    
          if (y < 50) {
            page = createPage();
            y = height - 50;
            currentX = x; // Reset x position when starting a new page
          }
    
          // 根据当前状态选择字体
          const currentFont = drawBoldStart ? boldFont : regularFont;
          
          // 如果词非空，进行渲染
          if (word.trim()) {
              // 添加词之间的空格（除了第一个词和标记为跳过空格的情况）
              if (currentX !== x && !skipNextSpace) {
                  page.drawText(' ', {
                      x: currentX,
                      y,
                      size,
                      font: currentFont,
                      color,
                  });
                  currentX += currentFont.widthOfTextAtSize(' ', size);
              }
              skipNextSpace = false;  // 重置标志
    
              // 渲染当前词
              page.drawText(word, {
                  x: currentX,
                  y,
                  size,
                  font: currentFont,
                  color,
              });
              
              // 更新 x 坐标
              currentX += currentFont.widthOfTextAtSize(word, size);
          }
      });
    
      return y;
    };

    const drawTextWithWrapping = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
      // Return immediately if text is empty or null
      if (!text) return y;
      // Sanitize the input text by removing any carriage return or newline characters
      // const sanitizedText = text.replace(/[\r\n]+/g, ' ').replace(/\s+/g, ' ').trim();
      const sanitizedText = text
      .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
      .replace(/\s+/g, ' ')      // Remove excessive spaces
      .replace(/，/g, ',')        // Replace full-width comma with a regular comma
      .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
      .replace(/[•●⚫⬤∙]\s*/g, '• ')
      .trim();
    
      const words = sanitizedText.split(' ');
      let line = '';
      let lines = [];
    
      words.forEach(word => {
        // Check if adding the next word exceeds the maxWidth
        if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
          line += word + ' ';
        } else {
          // If it exceeds, push the current line to the array and start a new line
          lines.push(line.trim()); // Trim extra space at the end of the line
          line = word + ' ';
        }
      });
    
      // Push the last line
      lines.push(line.trim());
    
      // Draw each line
      lines.forEach((line, index) => {
        y = drawText(line, x, y, size, font, rgb(0, 0, 0));
        y -= (lineHeightSpacing + 4); // Adjust the y position for the next line
      });
    
      return y;
    };

    const drawTextWithWrappingSkillsCategory = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
      if (!text) return y;
      // const sanitizedText = text.replace(/[\r\n]+/g, ' ').replace(/\s+/g, ' ').trim();
      const sanitizedText = text
      .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
      .replace(/\s+/g, ' ')      // Remove excessive spaces
      .replace(/，/g, ',')        // Replace full-width comma with a regular comma
      .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
      .replace(/[•●⚫⬤∙]\s*/g, '• ')
      .trim();
      const words = sanitizedText.split(' ');
      let line = '';
      let lines = [];
      words.forEach(word => {
        if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) >= maxWidth) {
          lines.push(line);
          line = word + ' ';
        } else if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) < maxWidth) {
          line += word + ' ';
        } else if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
          line += word + ' ';
        } else {
          lines.push(line);
          line = word + ' ';
        }
      });
      lines.push(line);
      lines.forEach((line, index) => {
        if (index === 0) {
          y = drawText(line, x, y, size, font, rgb(0, 0, 0));
        } else {
          y = drawText(line, 50, y, size, font, rgb(0, 0, 0));
        }
        y -= (lineHeightSpacing + 4);
      });
      return y;
    };

    const drawTextWithWrappingSkillsCategoryKey = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
      if (!text) return y;
      // const sanitizedText = text.replace(/[\r\n]+/g, ' ').replace(/\s+/g, ' ').trim();
      const sanitizedText = text
      .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
      .replace(/\s+/g, ' ')      // Remove excessive spaces
      .replace(/，/g, ',')        // Replace full-width comma with a regular comma
      .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
      .replace(/[•●⚫⬤∙]\s*/g, '• ')
      .trim();
      const words = sanitizedText.split(' ');
      let line = '';
      let lines = [];
      words.forEach(word => {
        if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) >= maxWidth) {
          lines.push(line);
          line = word + ' ';
        } else if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) < maxWidth) {
          line += word + ' ';
        } else if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
          line += word + ' ';
        } else {
          lines.push(line);
          line = word + ' ';
        }
      });
      lines.push(line);
      lines.forEach((line, index) => {
        if (index === 0) {
          y = drawText(line, x, y, size, font, rgb(0, 0, 0));
        } else {
          y = drawText(line, 50, y, size, font, rgb(0, 0, 0));
        }
      });
      return y;
    };

    // Header
    const renderHeader = () => {
      // Construct the header information dynamically
        const parts = [email, phone_number, user_location, personal_website].filter(item => item);
        const headerInfo = parts.join(' | ');

        // Header
        // let yPosition = height - 40;
        yPosition = drawText(name || '', 50, yPosition, name_fontsize, timesRomanBoldFont);

        // Only draw the header info if it's not empty
        if (headerInfo) {
            yPosition = drawTextWithWrapping(headerInfo, 50, yPosition - sectionSpacing, fontsize);
        }
        yPosition -= sectionSpacing;
    }

    // Summary
    const renderSummary = () => {
      // renderSummary(yPosition, summary)
      if (summary) {
        yPosition = drawTextWithWrapping(summary, 50, yPosition, fontsize, timesRomanFont);
        // Add extra space after the summary
        yPosition += (lineHeightSpacing + 4);
        yPosition -= sectionSpacing;
      }
    }

    // Education
    const renderEducation = () => {
      if (education_list && education_list.length > 0) {
        yPosition = drawSectionHeader('Education', yPosition);
        education_list.forEach((edu, index) => {
            yPosition = drawText(edu.university, 50, yPosition, fontsize, timesRomanBoldFont);

            // Calculate the position for the duration to be right-aligned
            let startDate = edu.university_start_month ? 
            `${monthMap[parseInt(edu.university_start_month, 10)]} ${edu.university_start_year}` : 
            `${edu.university_start_year}`;
            
            let endDate = edu.graduation_month ? 
              `${monthMap[parseInt(edu.graduation_month, 10)]} ${edu.graduation_year}` : 
              `${edu.graduation_year}`;

            const durationText = `${startDate} - ${endDate}`;
            const durationTextWidth = timesRomanFont.widthOfTextAtSize(durationText, fontsize);
            yPosition = drawText(durationText, width - durationTextWidth - 50, yPosition, fontsize);
            
            let degree_major_text = "";
            if (!edu.majors || edu.majors.trim() === "") {
                degree_major_text = edu.degrees;
            } else {
                degree_major_text = `${edu.degrees} in ${edu.majors}`;
            }
            yPosition = drawText(degree_major_text, 50, yPosition - lineHeightSpacing - 4, fontsize);

            if (edu.GPA) {
                yPosition = drawText(`GPA: ${edu.GPA}`, 50, yPosition - lineHeightSpacing - 4, fontsize);
            }

            // Check if the current element is the last one
            if (index === education_list.length - 1) {
                yPosition -= sectionSpacing;
              } else {
                yPosition -= sectionSpacing;
              }
        });
      }
    };

    // Experience
    const renderExperience = () => {
      if (experiences && experiences.length > 0) {
        yPosition = drawSectionHeader('Experience', yPosition);

        experiences.forEach(exp => {
            const title_text = exp.Experience.Title + " at " + exp.Experience.Company;
            yPosition = drawTextWithWrapping(title_text, 50, yPosition, fontsize, timesRomanBoldFont);
            if (exp.Experience.Location) {
                yPosition = drawText(exp.Experience.Location, 50, yPosition, fontsize);
            }
            if (exp.Experience.Duration) {
                const expDurationTextWidth = timesRomanFont.widthOfTextAtSize(exp.Experience.Duration, fontsize);
                yPosition = drawText(exp.Experience.Duration, width - expDurationTextWidth - 50, yPosition, fontsize);
            }
            yPosition -= (lineHeightSpacing + 4);
            exp.Experience.Responsibilities.forEach(resp => {
                if (showBoldKeywords) {
                    yPosition = drawBulletedText(resp, 50, yPosition);
                } else {
                    yPosition = drawBulletedTextNoBold(resp, 50, yPosition);
                }
            });
            yPosition += (lineHeightSpacing + 4);
            yPosition -= sectionSpacing;
        });
      }
    }
      
    // Skills
    const renderSkills = () => {
      if (skills && skills.length > 0) {
        // Add 20 vertical spacing before Skills section
        if (isSkillsCategorized) {
            yPosition = drawSectionHeader('Skills', yPosition);
            let lastValidIndex = -1;
            // First, identify the last valid index
            Object.keys(categorizedSkills).forEach((key, index) => {
                if (categorizedSkills[key] && categorizedSkills[key].length > 0) {
                    lastValidIndex = index;
                }
            });

            Object.keys(categorizedSkills).forEach((key, index) => {
                // Check if the value is an empty list or null
                if (!categorizedSkills[key] || categorizedSkills[key].length === 0) {
                    return; // Skip to the next iteration
                }

                let skillsText = categorizedSkills[key].join(', ');
                keyText = key + ': ';
                yPosition = drawTextWithWrappingSkillsCategoryKey(keyText, 50, yPosition, fontsize, timesRomanBoldFont);
                yPosition = drawTextWithWrappingSkillsCategory(skillsText, 50 + timesRomanBoldFont.widthOfTextAtSize(keyText, fontsize), yPosition, fontsize, timesRomanFont);

                // Check if the current key is the last valid element in categorizedSkills
                if (index === lastValidIndex) {
                    yPosition += (lineHeightSpacing + 4);
                    yPosition -= sectionSpacing;
                }
            });
        } else {
            yPosition = drawSectionHeader('Skills', yPosition);
            const skillsText = skills.join(', ');
            yPosition = drawTextWithWrapping(skillsText, 50, yPosition, fontsize, timesRomanFont);
            yPosition += (4 + lineHeightSpacing);
            yPosition -= sectionSpacing;
        }
      }
    }
      
    // Certificate: Add 20 vertical spacing before Certificates section
    const renderCertificates = () => {
      if (certificates && certificates.length > 0) {
        // Certificates
        yPosition = drawSectionHeader('Certificates', yPosition);
        certificates.forEach(cert => {
            const certNameText = '• ' + (cert.name || '');
            const certDateText = cert.achieved_date ? String(cert.achieved_date) : '';
            const certDateTextWidth = timesRomanFont.widthOfTextAtSize(certDateText, fontsize);
        
            yPosition = drawText(certNameText, 50, yPosition, fontsize, timesRomanFont);
            yPosition = drawText(certDateText, width - certDateTextWidth - 50, yPosition, fontsize);
        
            yPosition -= lineHeightSpacing + 4;
        });
        yPosition += (lineHeightSpacing + 4);
        yPosition -= sectionSpacing;
      }
    }

    renderHeader();

    sectionOrder.forEach(section => {
      switch(section) {
        case 'Summary':
          renderSummary();
          break;
        case 'Education':
          renderEducation();
          break;
        case 'Experience':
          renderExperience();
          break;
        case 'Skills':
          renderSkills();
          break;
        case 'Certificates':
          renderCertificates();
          break;
        default:
          break;
      }
    });
  }

  const generateTemplate2 = async (pdfDoc, timesRomanFont, timesRomanBoldFont) => {
    const timesRomanItalicFont = await pdfDoc.embedFont(StandardFonts.TimesRomanItalic);
    const timesRomanBoldItalicFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBoldItalic);
    const createPage = () => {
      const page = pdfDoc.addPage([595, 842]); // A4 size page dimensions
      return page;
    };

    let page = createPage();
    let { width, height } = page.getSize();
    let yPosition = height - 40;

    const drawText_center = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
      // Ensure text is not null or undefined
      const textToDraw = text || '';
      const sanitizedText = textToDraw
                  .replace(/，/g, ',')          // Replace full-width comma with a regular comma
                  .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
                  .replace(/[•●⚫⬤∙]\s*/g, '• ')
                  .trim();
      const textWidth = font.widthOfTextAtSize(sanitizedText, size);
      const xPosition = (595 - textWidth) / 2; 

      if (y < 50) {
        page = createPage();
        y = height - 50;
      }

      page.drawText(sanitizedText, { x: xPosition, y, size, font, color });
      return y;
    };

    const drawText = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
      // Ensure text is not null or undefined
      const textToDraw = text || '';

      if (y < 50) {
        page = createPage();
        y = height - 50;
      }
      page.drawText(textToDraw, { x, y, size, font, color });
      return y;
    };

    const drawSectionHeader = (text, y) => {
      y = drawText(text, 50, y, fontsize, timesRomanBoldFont, rgb(0, 0, 0));
      return y;
    };

    const drawAlignedText = (leftText, rightText, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
      const leftTextWidth = leftText ? font.widthOfTextAtSize(leftText, size) : 0;
      const rightTextWidth = rightText ? font.widthOfTextAtSize(rightText, size) : 0;
    
      if (y < 50) {
        page = createPage();
        y = height - 50;
      }
    
      if (leftText) {
        page.drawText(leftText, { x: 50, y, size, font, color });
      }
      
      if (rightText) {
        page.drawText(rightText, { x: width - rightTextWidth - 50, y, size, font, color });
      }
    
      return y;
    };
      
    const drawBulletedTextNoBold = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0), maxWidth = width - 100) => {
        const sanitizedText = text
        .replace(/[\r\n]+/g, ' ')
        .replace(/\s+/g, ' ')
        .replace(/，/g, ',')
        .replace(/‐|–|—/g, '-')
        .replace(/[•●⚫⬤∙]\s*/g, '• ')
        .trim();
        const bullet = '• ';
        const bulletWidth = font.widthOfTextAtSize(bullet, size);
        const indent = x + bulletWidth; // Position for the subsequent lines
        const words = sanitizedText.split(' ');
        let line = '';
        let firstLine = true;

        words.forEach(word => {
          const testLine = line + word + ' ';
          const testLineWidth = font.widthOfTextAtSize((firstLine ? bullet : '') + testLine, size);

          if (testLineWidth < maxWidth) {
            line = testLine;
          } else {
            y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
            y -= lineHeightSpacing + 4;
            line = word + ' ';
            firstLine = false;
          }
        });

        y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
        return y - lineHeightSpacing - 4;
    };

    const normalizeText = (text) => {
      if (!text) return '';
      return text.toLowerCase()
        .replace(/[-](?=\w)/g, ' ')
        .replace(/\.(?=[A-Za-z])/g, ' ')
        .replace(/[,!?\.]$/g, '')
        .replace(/[,!?]/g, '')
        .replace(/[•●⚫⬤∙]\s*/g, '• ')
        .trim();
    };
        
    const drawBulletedText = (text, x, y, size = fontsize, regularFont = timesRomanFont, boldFont = timesRomanBoldFont, color = rgb(0, 0, 0), maxWidth = width - 100) => {
      if (!text) return y;
    
      // Sanitize and normalize the input text
      const sanitizedText = text
        .replace(/[\r\n]+/g, ' ')
        .replace(/\s+/g, ' ')
        .replace(/，/g, ',')
        .replace(/‐|–|—/g, '-')
        .replace(/[•●⚫⬤∙]\s*/g, '• ')
        .trim();
    
      // Special markers for bold text
      const BOLD_START = ' [[BOLD]] '; // Using string markers instead
      const BOLD_END = ' [[/BOLD]] ';
    
      // First pass: Mark all keywords in text
      let processedText = sanitizedText;
    
      combinedSkills.forEach(keyword => {
        if (!keyword) return;
        
        const normalizedKeyword = normalizeText(keyword);
        
        // Handle multi-word keywords
        if (normalizedKeyword.includes(' ')) {
          const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
          const regexPattern = escapedKeyword
              .split(' ')
              .join('(?:[-. ]|(?= ))');
          const keywordRegex = new RegExp(`\\b${regexPattern}\\b`, 'gi');
          
          // 修改这里：先找到匹配的关键词，然后检查后面的标点符号
          processedText = processedText.replace(keywordRegex, match => {
              // 分离词和标点符号
              const punctMatch = match.match(/^(.*?)([\s,，.。!！?？]*)$/);
              if (punctMatch) {
                  const [, word, punct] = punctMatch;
                  return `${BOLD_START}${word}${BOLD_END}${punct}`;
              }
              return `${BOLD_START}${match}${BOLD_END}`;
          });
        } else {
          // Handle single-word keywords
          const words = processedText.split(/(\s+)/);
          processedText = words.map(word => {
            // 分离词和标点符号
            const punctMatch = word.match(/^(.*?)([\s,，.。!！?？]*)$/);
            if (punctMatch) {
              const [, mainWord, punct] = punctMatch;
              if (normalizeText(mainWord) === normalizedKeyword) {
                return `${BOLD_START}${mainWord}${BOLD_END}${punct}`;
              }
            }
            return word;
          }).join('');
      }
    });
    
      // Drawing parameters
      const bullet = '• ';
      const bulletWidth = regularFont.widthOfTextAtSize(bullet, size);
      const indent = x + bulletWidth;
      const words = processedText.split(' ').filter(word => word.length > 0);
      let line = '';
      let firstLine = true;
  
    
      let boldStart = false;
      let lastLineWidth = firstLine ? regularFont.widthOfTextAtSize(bullet, size) : 0;
      words.forEach(word => {
        if (word === BOLD_START.trim()) {
            boldStart = true;
            // 移除加粗开始标记
            word = word.replace(BOLD_START, '');
        }
        
        // 检查是否包含加粗结束标记
        if (word === BOLD_END.trim()) {
            boldStart = false;
            // 移除加粗结束标记
            word = word.replace(BOLD_END, '');
        }
    
        // 根据当前状态选择字体
        const currentFont = boldStart ? boldFont : regularFont;
    
        const wordWidth = (word === BOLD_START.trim() || word === BOLD_END.trim()) 
        ? 0 
        : currentFont.widthOfTextAtSize(word + ' ', size);
        const testLineWidth = lastLineWidth + wordWidth;
    
        const isPunctuation = /^[,.!?;:""''()\[\]<>]+$/.test(word);
    
        if (testLineWidth < maxWidth || isPunctuation || 
          word === BOLD_START.trim() || 
          word === BOLD_END.trim()) {
            // 保存原始词，包括其当前的加粗状态
            line = line + word + ' ';
            lastLineWidth = testLineWidth;
        } else {
            // 换行并渲染
            y = drawTextWithStyles((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, regularFont, boldFont, color, BOLD_START, BOLD_END);
            y -= lineHeightSpacing + 4;
            line = word + ' ';
            firstLine = false;
            lastLineWidth = regularFont.widthOfTextAtSize(word + ' ', size);
        }
      });
    
      // Draw the last line
      y = drawTextWithStyles((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, regularFont, boldFont, color, BOLD_START, BOLD_END);
      return y - lineHeightSpacing - 4;
    };
        
    let drawBoldStart = false;
    
    const drawTextWithStyles = (line, x, y, size, regularFont, boldFont, color, BOLD_START, BOLD_END) => {
      let currentX = x;
      const words = line.split(' ');
      let skipNextSpace = false;  // 添加标志来控制是否跳过下一个空格
      
      words.forEach((word, index) => {
          // 跳过空词
          if (!word) return;
          
          // 检查是否是加粗结束标记
          if (word === BOLD_END.trim()) {
              drawBoldStart = false;
              // 如果下一个词是标点符号，设置标志
              if (index < words.length - 1 && /^[,，.。!！?？]/.test(words[index + 1])) {
                  skipNextSpace = true;
              }
              return;
          }
          
          // 检查是否是加粗开始标记
          if (word === BOLD_START.trim()) {
              drawBoldStart = true;
              return;
          }
    
          if (y < 50) {
            page = createPage();
            y = height - 50;
            currentX = x; // Reset x position when starting a new page
          }
    
          // 根据当前状态选择字体
          const currentFont = drawBoldStart ? boldFont : regularFont;
          
          // 如果词非空，进行渲染
          if (word.trim()) {
              // 添加词之间的空格（除了第一个词和标记为跳过空格的情况）
              if (currentX !== x && !skipNextSpace) {
                  page.drawText(' ', {
                      x: currentX,
                      y,
                      size,
                      font: currentFont,
                      color,
                  });
                  currentX += currentFont.widthOfTextAtSize(' ', size);
              }
              skipNextSpace = false;  // 重置标志
    
              // 渲染当前词
              page.drawText(word, {
                  x: currentX,
                  y,
                  size,
                  font: currentFont,
                  color,
              });
              
              // 更新 x 坐标
              currentX += currentFont.widthOfTextAtSize(word, size);
          }
      });
    
      return y;
    };

    const drawTextWithWrapping = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
      // Return immediately if text is empty or null
      if (!text) return y;
      // Sanitize the input text by removing any carriage return or newline characters
      const sanitizedText = text
      .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
      .replace(/\s+/g, ' ')      // Remove excessive spaces
      .replace(/，/g, ',')        // Replace full-width comma with a regular comma
      .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
      .replace(/[•●⚫⬤∙]\s*/g, '• ')
      .trim();
    
      const words = sanitizedText.split(' ');
      let line = '';
      let lines = [];
    
      words.forEach(word => {
        // Check if adding the next word exceeds the maxWidth
        if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
          line += word + ' ';
        } else {
          // If it exceeds, push the current line to the array and start a new line
          lines.push(line.trim()); // Trim extra space at the end of the line
          line = word + ' ';
        }
      });
    
      // Push the last line
      lines.push(line.trim());
    
      // Draw each line
      lines.forEach((line, index) => {
        y = drawText(line, x, y, size, font, rgb(0, 0, 0));
        y -= lineHeightSpacing + 4; // Adjust the y position for the next line
      });
    
      return y;
    };

    const drawTextWithWrappingSkillsCategory = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
      if (!text) return y;
      const sanitizedText = text
      .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
      .replace(/\s+/g, ' ')      // Remove excessive spaces
      .replace(/，/g, ',')        // Replace full-width comma with a regular comma
      .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
      .replace(/[•●⚫⬤∙]\s*/g, '• ')
      .trim();
      const words = sanitizedText.split(' ');
      let line = '';
      let lines = [];
      words.forEach(word => {
        if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) >= maxWidth) {
          lines.push(line);
          line = word + ' ';
        } else if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) < maxWidth) {
          line += word + ' ';
        } else if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
          line += word + ' ';
        } else {
          lines.push(line);
          line = word + ' ';
        }
      });
      lines.push(line);
      lines.forEach((line, index) => {
        if (index === 0) {
          y = drawText(line, x, y, size, font, rgb(0, 0, 0));
        } else {
          y = drawText(line, 50, y, size, font, rgb(0, 0, 0));
        }
        y -= lineHeightSpacing + 4;
      });
      return y;
    };

    const drawTextWithWrappingSkillsCategoryKey = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
      if (!text) return y;
      const sanitizedText = text
      .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
      .replace(/\s+/g, ' ')      // Remove excessive spaces
      .replace(/，/g, ',')        // Replace full-width comma with a regular comma
      .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
      .replace(/[•●⚫⬤∙]\s*/g, '• ')
      .trim();
      const words = sanitizedText.split(' ');
      let line = '';
      let lines = [];
      words.forEach(word => {
        if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) >= maxWidth) {
          lines.push(line);
          line = word + ' ';
        } else if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) < maxWidth) {
          line += word + ' ';
        } else if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
          line += word + ' ';
        } else {
          lines.push(line);
          line = word + ' ';
        }
      });
      lines.push(line);
      lines.forEach((line, index) => {
        if (index === 0) {
          y = drawText(line, x, y, size, font, rgb(0, 0, 0));
        } else {
          y = drawText(line, 50, y, size, font, rgb(0, 0, 0));
        }
      });
      return y;
    };

    // Header
    const renderHeader = () => {
      // Construct the header information dynamically
      const parts = [user_location, phone_number, email, personal_website].filter(item => item);
      const headerInfo = parts.join(' | ');

      
      yPosition = drawText_center(name || '', 50, yPosition, name_fontsize, timesRomanBoldFont);
      // Only draw the header info if it's not empty
      if (headerInfo) {
        // write and add adding section spacing
        yPosition = drawText_center(headerInfo, 50, yPosition - sectionSpacing, fontsize);
      }
      yPosition -= sectionSpacing;
    }
       
    // Summary
    const renderSummary = () => {
      if (summary) {
        yPosition = drawSectionHeader('SUMMARY', yPosition);

        yPosition -= sectionBetweenLine;

        // Draw a line 
        page.drawLine({
          start: { x: 50, y: yPosition },
          end: { x: width - 50, y: yPosition },
          thickness: 1,
          color: rgb(0, 0, 0),
        });
        
        yPosition = drawTextWithWrapping(summary, 50, yPosition-lineHeightSpacing-4, fontsize, timesRomanFont);
        // Add extra space after the summary
        
        yPosition += (lineHeightSpacing + 4);
        yPosition -= sectionSpacing;
      }
    }

    // Education
    const renderEducation = () => {
      if (education_list && education_list.length > 0) {
        yPosition = drawSectionHeader('EDUCATION', yPosition);
        
        yPosition -= sectionBetweenLine; 

      // Draw a line after EDUCATION
        page.drawLine({
          start: { x: 50, y: yPosition },
          end: { x: width - 50, y: yPosition },
          thickness: 1,
          color: rgb(0, 0, 0),
        });
        yPosition -= 4; 
      

        education_list.forEach((edu, index) => {
          const universityText = `${edu.university}`;
          yPosition = drawText(universityText, 50, yPosition - lineHeightSpacing, fontsize, timesRomanBoldFont); // University and location left-aligned, bold, and on its own line
          yPosition -= 4;
          let startDate = edu.university_start_month ? 
            `${monthMap[parseInt(edu.university_start_month, 10)]} ${edu.university_start_year}` : 
            `${edu.university_start_year}`;
          
          let endDate = edu.graduation_month ? 
            `${monthMap[parseInt(edu.graduation_month, 10)]} ${edu.graduation_year}` : 
            `${edu.graduation_year}`;

          const durationText = `${startDate} - ${endDate}`;
          let degree_major_text = "";
          if (!edu.majors || edu.majors.trim() === "") {
              degree_major_text = edu.degrees;
          } else {
              degree_major_text = `${edu.degrees} in ${edu.majors}`;
          }
          yPosition = drawAlignedText(degree_major_text, durationText, yPosition - lineHeightSpacing, fontsize, timesRomanFont);
          
          if (edu.GPA) {
            yPosition -= 4;
            const gpaText = `GPA: ${edu.GPA}`;
            yPosition = drawText(gpaText, 50, yPosition - lineHeightSpacing, fontsize, timesRomanFont); // Adjust x position as needed
          }
        
          // Check if the current element is the last one, adding section spacing
          if (index === education_list.length - 1) {
            yPosition -= sectionSpacing;
          } else {
            yPosition += lineHeightSpacing;
            yPosition -= sectionSpacing;
          }
        });
        
      }
    }

    // Work Experience
    const renderWorkExperience = () => {
      if (revisedWorkExp && revisedWorkExp.length > 0) {
        yPosition = drawSectionHeader('WORK EXPERIENCE', yPosition);

        yPosition -= sectionBetweenLine;

        page.drawLine({
          start: { x: 50, y: yPosition },
          end: { x: width - 50, y: yPosition },
          thickness: 1,
          color: rgb(0, 0, 0),
        });
        yPosition -= 4; 
      
        revisedWorkExp.forEach((exp, index) => {
          yPosition = drawAlignedText(exp.Experience.Company, exp.Experience.Location, yPosition - lineHeightSpacing, fontsize, timesRomanBoldFont);
          // yPosition = drawAlignedText(exp.Experience.Title, exp.Experience.Duration, yPosition - 10, 12, timesRomanFont);
          yPosition = drawAlignedText(exp.Experience.Title, "", yPosition - lineHeightSpacing - 4, fontsize, timesRomanBoldItalicFont);
          yPosition = drawAlignedText("", exp.Experience.Duration, yPosition, fontsize, timesRomanItalicFont);
      
          yPosition -= (lineHeightSpacing + 4);
          
          exp.Experience.Responsibilities.forEach(resp => {
            if (showBoldKeywords) {
              yPosition = drawBulletedText(resp, 50, yPosition);
            } else {
              yPosition = drawBulletedTextNoBold(resp, 50, yPosition);
            }
          });
          // Check if the current element is the last one, adding section spacing
          if (index === revisedWorkExp.length - 1) {
              yPosition += (lineHeightSpacing + 4);
              yPosition -= sectionSpacing;
          } else {
              yPosition += (lineHeightSpacing + 4 + lineHeightSpacing);
              yPosition -= sectionSpacing;
          }
        });
      }
    }
      
    // Project Experience
    const renderProjectExperience = () => {
      if (revisedProjExp && revisedProjExp.length > 0) {
        yPosition = drawSectionHeader('PROJECT EXPERIENCE', yPosition);
        yPosition -= sectionBetweenLine;

        page.drawLine({
          start: { x: 50, y: yPosition },
          end: { x: width - 50, y: yPosition },
          thickness: 1,
          color: rgb(0, 0, 0),
        });
        yPosition -= 4; 
        revisedProjExp.forEach((exp, index) => {
        
          if (exp.Experience) { // Ensure 'Experience' object exists

            yPosition = drawAlignedText(exp.Experience.Company, exp.Experience.Location, yPosition - lineHeightSpacing, fontsize, timesRomanBoldFont);
      
            yPosition = drawAlignedText(exp.Experience.Title, "", yPosition - lineHeightSpacing - 4, fontsize, timesRomanBoldItalicFont);
            yPosition = drawAlignedText("", exp.Experience.Duration, yPosition, fontsize, timesRomanItalicFont);

            yPosition -= (lineHeightSpacing + 4);

            exp.Experience.Responsibilities.forEach(resp => {
              if (showBoldKeywords) {
                  yPosition = drawBulletedText(resp, 50, yPosition);
              } else {
                  yPosition = drawBulletedTextNoBold(resp, 50, yPosition);
              }
            });
            // Check if the current element is the last one, adding section spacing
            if (index === revisedProjExp.length - 1) {
              yPosition += (lineHeightSpacing + 4);
              yPosition -= sectionSpacing;
            } else {
              yPosition += (lineHeightSpacing + 4 + lineHeightSpacing);
              yPosition -= sectionSpacing;
            }
          }
        });
      }
    }

    // Skills
    const renderSkills = () => {
      if (skills && skills.length > 0) {
        yPosition = drawSectionHeader('SKILLS', yPosition);
        yPosition -= sectionBetweenLine;

        page.drawLine({
          start: { x: 50, y: yPosition },
          end: { x: width - 50, y: yPosition },
          thickness: 1,
          color: rgb(0, 0, 0),
        });
        yPosition -= (4 + lineHeightSpacing);
        if (isSkillsCategorized) {
          let lastValidIndex = -1;
          // First, identify the last valid index
          Object.keys(categorizedSkills).forEach((key, index) => {
              if (categorizedSkills[key] && categorizedSkills[key].length > 0) {
                  lastValidIndex = index;
              }
          });

          Object.keys(categorizedSkills).forEach((key, index) => {
              // Check if the value is an empty list or null
              if (!categorizedSkills[key] || categorizedSkills[key].length === 0) {
                  return; // Skip to the next iteration
              }

              let skillsText = categorizedSkills[key].join(', ');
              keyText = key + ': ';
              yPosition = drawTextWithWrappingSkillsCategoryKey(keyText, 50, yPosition, fontsize, timesRomanBoldFont);
              yPosition = drawTextWithWrappingSkillsCategory(skillsText, 50 + timesRomanBoldFont.widthOfTextAtSize(keyText, fontsize), yPosition, fontsize, timesRomanFont);

              // Check if the current key is the last valid element in categorizedSkills, add sectionspacing
              if (index === lastValidIndex) {
                  yPosition += (lineHeightSpacing + 4);
                  yPosition -= sectionSpacing;
              }
          });
          } else {
              const skillsText = skills.join(', ');
              yPosition = drawTextWithWrapping(skillsText, 50, yPosition, fontsize, timesRomanFont);
              // add section spacing
              yPosition += (lineHeightSpacing + 4);
              yPosition -= sectionSpacing;
          }
      }
    }

    // Certificates
    const renderCertificates = () => {
      if (certificates && certificates.length > 0) {
        yPosition = drawSectionHeader('CERTIFICATES', yPosition);
        yPosition -= sectionBetweenLine;

        page.drawLine({
          start: { x: 50, y: yPosition },
          end: { x: width - 50, y: yPosition },
          thickness: 1,
          color: rgb(0, 0, 0),
        });
        yPosition -= (4 + lineHeightSpacing);

        certificates.forEach(cert => {
          const certNameText = '• ' + (cert.name || '');
          const certDateText = cert.achieved_date ? String(cert.achieved_date) : '';
          const certDateTextWidth = timesRomanFont.widthOfTextAtSize(certDateText, fontsize);
          yPosition = drawText(certNameText, 50, yPosition, fontsize, timesRomanFont);
          yPosition = drawText(certDateText, width - certDateTextWidth - 50, yPosition, fontsize);
          yPosition -= lineHeightSpacing + 4;
        });
        yPosition += (lineHeightSpacing + 4);
        yPosition -= sectionSpacing;
      }
    }

    renderHeader();

    sectionOrder.forEach(section => {
      switch(section) {
        case 'Summary':
          renderSummary();
          break;
        case 'Education':
          renderEducation();
          break;
        case 'Work Experience':
          renderWorkExperience();
          break;
        case 'Project Experience':
          renderProjectExperience();
          break;
        case 'Skills':
          renderSkills();
          break;
        case 'Certificates':
          renderCertificates();
          break;
        default:
          break;
      }
    });
  };

  const generateTemplate3 = async (pdfDoc, timesRomanFont, timesRomanBoldFont) => {
    const createPage = () => {
      const page = pdfDoc.addPage([595, 842]); // A4 size page dimensions
      return page;
    };

    const getPage = (pageIndex) => {
      if (pageIndex < pdfDoc.getPageCount()) {
          return pdfDoc.getPage(pageIndex); // Access the existing page
      } else {
          return createPage(); // Create a new page if it doesn't exist
      }
    };

    let currentPageIndex = 0;
    let page = getPage(currentPageIndex); // Start with the first page
    let { width, height } = page.getSize();

    const drawText_left = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
      // Ensure text is not null or undefined
      const textToDraw = text || '';
      const textWidth = font.widthOfTextAtSize(textToDraw, size);
      const xPosition = 50;

      if (y < 50) {
        // page = createPage();
        currentPageIndex += 1;
        page = getPage(currentPageIndex);
        y = height - 50;
      }

      page.drawText(text, { x: xPosition, y, size, font, color });
      return y;
    };

    const drawText_right = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
      // Ensure text is not null or undefined
      const textToDraw = text || '';
      const sanitizedText = textToDraw
              .replace(/，/g, ',')          // Replace full-width comma with a regular comma
              .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
              .replace(/[•●⚫⬤∙]\s*/g, '• ')
              .trim();
      const textWidth = font.widthOfTextAtSize(sanitizedText, size);
      const xPosition = 397;

      if (y < 50) {
        // page = createPage();
        currentPageIndex += 1;
        page = getPage(currentPageIndex);
        y = height - 50;
      }

      page.drawText(sanitizedText, { x: xPosition, y, size, font, color });
      return y;
    };

    const drawText = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
      // Ensure text is not null or undefined
      const textToDraw = text || '';

      if (y < 50) {
        // page = createPage();
        currentPageIndex += 1;
        page = getPage(currentPageIndex);
        y = height - 50;
      }
      page.drawText(textToDraw, { x, y, size, font, color });
      return y;
    };

    const drawSectionHeader = (text, y) => {
      y = drawText(text, 50, y, fontsize, timesRomanBoldFont, rgb(0.11, 0.533, 0.929));
      return y - 20;
    };

    const drawSectionHeader_right = (text, y) => {
      y = drawText_right(text, 50, y, fontsize, timesRomanBoldFont, rgb(0.11, 0.533, 0.929));
      return y - 20;
    };
      
    const drawBulletedTextNoBold = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0), maxWidth = 330) => {
      const sanitizedText = text
          .replace(/[\r\n]+/g, ' ')
          .replace(/\s+/g, ' ')
          .replace(/，/g, ',')
          .replace(/‐|–|—/g, '-')
          .replace(/[•●⚫⬤∙]\s*/g, '• ')
          .trim();
      const bullet = '• ';
      const bulletWidth = font.widthOfTextAtSize(bullet, size);
      const indent = x + bulletWidth; // Position for the subsequent lines
      const words = sanitizedText.split(' ');
      let line = '';
      let firstLine = true;

      words.forEach(word => {
        const testLine = line + word + ' ';
        const testLineWidth = font.widthOfTextAtSize((firstLine ? bullet : '') + testLine, size);

        if (testLineWidth < maxWidth) {
          line = testLine;
        } else {
          y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
          y -= lineHeightSpacing + 4;
          line = word + ' ';
          firstLine = false;
        }
      });

      y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
      return y - lineHeightSpacing - 4;
    };

    const normalizeText = (text) => {
      if (!text) return '';
      return text.toLowerCase()
        .replace(/[-](?=\w)/g, ' ')
        .replace(/\.(?=[A-Za-z])/g, ' ')
        .replace(/[,!?\.]$/g, '')
        .replace(/[,!?]/g, '')
        .replace(/[•●⚫⬤∙]\s*/g, '• ')
        .trim();
    };
        
    const drawBulletedText = (text, x, y, size = fontsize, regularFont = timesRomanFont, boldFont = timesRomanBoldFont, color = rgb(0, 0, 0), maxWidth = 330) => {
      if (!text) return y;
    
      // Sanitize and normalize the input text
      const sanitizedText = text
          .replace(/[\r\n]+/g, ' ')
          .replace(/\s+/g, ' ')
          .replace(/，/g, ',')
          .replace(/‐|–|—/g, '-')
          .replace(/[•●⚫⬤∙]\s*/g, '• ')
          .trim();
    
      // Special markers for bold text
      const BOLD_START = ' [[BOLD]] '; // Using string markers instead
      const BOLD_END = ' [[/BOLD]] ';
    
      // First pass: Mark all keywords in text
      let processedText = sanitizedText;
    
      combinedSkills.forEach(keyword => {
        if (!keyword) return;
        
        const normalizedKeyword = normalizeText(keyword);
        
        // Handle multi-word keywords
        if (normalizedKeyword.includes(' ')) {
            const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const regexPattern = escapedKeyword
                .split(' ')
                .join('(?:[-. ]|(?= ))');
            const keywordRegex = new RegExp(`\\b${regexPattern}\\b`, 'gi');
            
            // 修改这里：先找到匹配的关键词，然后检查后面的标点符号
            processedText = processedText.replace(keywordRegex, match => {
                // 分离词和标点符号
                const punctMatch = match.match(/^(.*?)([\s,，.。!！?？]*)$/);
                if (punctMatch) {
                    const [, word, punct] = punctMatch;
                    return `${BOLD_START}${word}${BOLD_END}${punct}`;
                }
                return `${BOLD_START}${match}${BOLD_END}`;
            });
        } else {
            // Handle single-word keywords
            const words = processedText.split(/(\s+)/);
            processedText = words.map(word => {
                // 分离词和标点符号
                const punctMatch = word.match(/^(.*?)([\s,，.。!！?？]*)$/);
                if (punctMatch) {
                    const [, mainWord, punct] = punctMatch;
                    if (normalizeText(mainWord) === normalizedKeyword) {
                        return `${BOLD_START}${mainWord}${BOLD_END}${punct}`;
                    }
                }
                return word;
            }).join('');
        }
    });
    
      // Drawing parameters
      const bullet = '• ';
      const bulletWidth = regularFont.widthOfTextAtSize(bullet, size);
      const indent = x + bulletWidth;
      const words = processedText.split(' ').filter(word => word.length > 0);
      let line = '';
      let firstLine = true;
    
      let boldStart = false;
      let lastLineWidth = firstLine ? regularFont.widthOfTextAtSize(bullet, size) : 0;
      words.forEach(word => {
        if (word === BOLD_START.trim()) {
            boldStart = true;
            // 移除加粗开始标记
            word = word.replace(BOLD_START, '');
        }
        
        // 检查是否包含加粗结束标记
        if (word === BOLD_END.trim()) {
            boldStart = false;
            // 移除加粗结束标记
            word = word.replace(BOLD_END, '');
        }
    
        // 根据当前状态选择字体
        const currentFont = boldStart ? boldFont : regularFont;
    
        const wordWidth = (word === BOLD_START.trim() || word === BOLD_END.trim()) 
        ? 0 
        : currentFont.widthOfTextAtSize(word + ' ', size);
        const testLineWidth = lastLineWidth + wordWidth;
    
        const isPunctuation = /^[,.!?;:""''()\[\]<>]+$/.test(word);
    
        if (testLineWidth < maxWidth || isPunctuation || 
          word === BOLD_START.trim() || 
          word === BOLD_END.trim()) {
            // 保存原始词，包括其当前的加粗状态
            line = line + word + ' ';
            lastLineWidth = testLineWidth;
        } else {
            // 换行并渲染
            y = drawTextWithStyles((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, regularFont, boldFont, color, BOLD_START, BOLD_END);
            y -= lineHeightSpacing + 4;
            line = word + ' ';
            firstLine = false;
            lastLineWidth = regularFont.widthOfTextAtSize(word + ' ', size);
        }
      });
    
      // Draw the last line
      y = drawTextWithStyles((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, regularFont, boldFont, color, BOLD_START, BOLD_END);
      return y - lineHeightSpacing - 4;
    }; 
        
    let drawBoldStart = false;
   
    const drawTextWithStyles = (line, x, y, size, regularFont, boldFont, color, BOLD_START, BOLD_END) => {
      let currentX = x;
      const words = line.split(' ');
      let skipNextSpace = false;  // 添加标志来控制是否跳过下一个空格
      
      words.forEach((word, index) => {
          // 跳过空词
          if (!word) return;
          
          // 检查是否是加粗结束标记
          if (word === BOLD_END.trim()) {
              drawBoldStart = false;
              // 如果下一个词是标点符号，设置标志
              if (index < words.length - 1 && /^[,，.。!！?？]/.test(words[index + 1])) {
                  skipNextSpace = true;
              }
              return;
          }
          
          // 检查是否是加粗开始标记
          if (word === BOLD_START.trim()) {
              drawBoldStart = true;
              return;
          }
    
          if (y < 50) {
            page = createPage();
            y = height - 50;
            currentX = x; // Reset x position when starting a new page
          }
    
          // 根据当前状态选择字体
          const currentFont = drawBoldStart ? boldFont : regularFont;
          
          // 如果词非空，进行渲染
          if (word.trim()) {
              // 添加词之间的空格（除了第一个词和标记为跳过空格的情况）
              if (currentX !== x && !skipNextSpace) {
                  page.drawText(' ', {
                      x: currentX,
                      y,
                      size,
                      font: currentFont,
                      color,
                  });
                  currentX += currentFont.widthOfTextAtSize(' ', size);
              }
              skipNextSpace = false;  // 重置标志
    
              // 渲染当前词
              page.drawText(word, {
                  x: currentX,
                  y,
                  size,
                  font: currentFont,
                  color,
              });
              
              // 更新 x 坐标
              currentX += currentFont.widthOfTextAtSize(word, size);
          }
      });
    
      return y;
    };

    const drawBulletedText_right = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0), maxWidth = width - 50) => {
      const bullet = '• ';
      const bulletWidth = font.widthOfTextAtSize(bullet, size);
      const indent = x + bulletWidth; // Position for the subsequent lines
      const words = text.split(' ');
      let line = '';
      let firstLine = true;

      words.forEach(word => {
        const testLine = line + word + ' ';
        const testLineWidth = font.widthOfTextAtSize((firstLine ? bullet : '') + testLine, size);

        if (testLineWidth < maxWidth - x) {
          line = testLine;
        } else {
          y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
          y -= (lineHeightSpacing + 4);
          line = word + ' ';
          firstLine = false;
        }
      });

      y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
      return y - lineHeightSpacing - 4;
    };

    const drawTextWithWrapping = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = 330) => {
      // Return immediately if text is empty or null
      if (!text) return y;
      // Sanitize the input by removing newline and carriage return characters and collapsing multiple spaces
      const sanitizedText = text
      .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
      .replace(/\s+/g, ' ')      // Remove excessive spaces
      .replace(/，/g, ',')        // Replace full-width comma with a regular comma
      .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
      .replace(/[•●⚫⬤∙]\s*/g, '• ')
      .trim();
    
      const words = sanitizedText.split(' ');
      let line = '';
      let lines = [];
    
      words.forEach(word => {
        // Check if adding the word exceeds the maxWidth
        if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
          line += word + ' ';
        } else {
          // If it exceeds, push the current line to the array and start a new line
          lines.push(line.trim());  // Trim any extra space at the end of the line
          line = word + ' ';
        }
      });
    
      // Push the last line
      lines.push(line.trim());
    
      // Draw each line
      lines.forEach((line, index) => {
        y = drawText(line, x, y, size, font, rgb(0, 0, 0));  // Draw the line
        y -= lineHeightSpacing + 4;  // Adjust y position for the next line
      });
    
      return y;
    };

        
    const drawTextWithWrapping_right = (text, x = 397, y, size = fontsize, font = timesRomanFont, maxWidth = width - 70) => {
      // Return immediately if text is empty or null
      if (!text) return y;
    
      // Sanitize the input text by removing carriage return, newline characters, and collapsing extra spaces
      const sanitizedText = text
      .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
      .replace(/\s+/g, ' ')      // Remove excessive spaces
      .replace(/，/g, ',')        // Replace full-width comma with a regular comma
      .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
      .replace(/[•●⚫⬤∙]\s*/g, '• ')
      .trim();
    
      const words = sanitizedText.split(' ');
      let line = '';
      let lines = [];
    
      words.forEach(word => {
        // Check if the current line plus the new word exceeds maxWidth
        if (font.widthOfTextAtSize(line + word, size) < maxWidth - x) {
          line += word + ' ';
        } else {
          // If it exceeds, push the current line to the array and start a new line
          lines.push(line.trim()); // Trim any extra space at the end of the line
          line = word + ' ';
        }
      });
    
      // Push the last line if it's not empty
      if (line) lines.push(line.trim());
    
      // Draw each line at the specified x and y position
      lines.forEach((line, index) => {
        y = drawText(line, x, y, size, font, rgb(0, 0, 0));
        y -= (lineHeightSpacing + 4); // Adjust y position for the next line
      });
    
      return y;
    };

    const drawTextWithWrappingSkillsCategory = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
      if (!text) return y;
      // const sanitizedText = text.replace(/[\r\n]+/g, ' ').replace(/\s+/g, ' ').trim();
      const sanitizedText = text
      .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
      .replace(/\s+/g, ' ')      // Remove excessive spaces
      .replace(/，/g, ',')        // Replace full-width comma with a regular comma
      .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
      .replace(/[•●⚫⬤∙]\s*/g, '• ')
      .trim();
      const words = sanitizedText.split(' ');
      let line = '';
      let lines = [];
      words.forEach(word => {
        if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) >= maxWidth) {
          lines.push(line);
          line = word + ' ';
        } else if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) < maxWidth) {
          line += word + ' ';
        } else if (x + font.widthOfTextAtSize(line + word, size) - 50 < maxWidth) {
          line += word + ' ';
        } else {
          lines.push(line);
          line = word + ' ';
        }
      });
      lines.push(line);
      lines.forEach((line, index) => {
        if (index === 0) {
          y = drawText(line, x, y, size, font, rgb(0, 0, 0));
        } else {
          y = drawText(line, 397, y, size, font, rgb(0, 0, 0));
        }
        y -= (lineHeightSpacing + 4);
      });
      return y;
    };

    // Header
    // Construct the header information dynamically
    const parts = [personal_website, email, phone_number].filter(item => item);
    //for left column
    let yPosition = height - 40;
    //for right column
    let yPosition_right_column = height - 40;
    // Only draw the header info if it's not empty
    if (parts) {
        parts.map((element, index) => {
            yPosition_right_column = drawText_right(element, 50, yPosition_right_column, fontsize);
            yPosition_right_column -= (lineHeightSpacing + 4);
        });
    }
    if (yPosition_right_column > 757) {
        yPosition_right_column = 757;
    }
    yPosition_right_column -= 10;
       

    // Education
    if (education_list && education_list.length > 0) {
      yPosition_right_column = drawSectionHeader_right('EDUCATION', yPosition_right_column);
      
      education_list.forEach((edu, index) => {
        const universityText = `${edu.university}`;
        yPosition_right_column = drawTextWithWrapping_right(universityText, 397, yPosition_right_column, fontsize, timesRomanBoldFont);
        yPosition_right_column += (lineHeightSpacing + 4);
        yPosition_right_column -= (lineHeightSpacing + 4);
        let degree_major_text = "";
        if (!edu.majors || edu.majors.trim() === "") {
            degree_major_text = edu.degrees;
        } else {
            degree_major_text = `${edu.degrees} in ${edu.majors}`;
        }
        const durationText = `${monthMap[parseInt(edu.university_start_month, 10)]} ${edu.university_start_year} - ${monthMap[parseInt(edu.graduation_month, 10)]} ${edu.graduation_year}`;
        yPosition_right_column = drawTextWithWrapping_right(degree_major_text, 397, yPosition_right_column, fontsize, timesRomanFont);
        yPosition_right_column += (lineHeightSpacing + 4);
        yPosition_right_column -= (lineHeightSpacing + 4);
        if (edu.GPA) {
            const gpaText = `GPA: ${edu.GPA}`;
            yPosition_right_column = drawTextWithWrapping_right(gpaText, 397, yPosition_right_column, fontsize, timesRomanFont); // Adjust x position as needed
            yPosition_right_column += (lineHeightSpacing + 4);
            yPosition_right_column -= (lineHeightSpacing + 4);
        }
        yPosition_right_column = drawText_right(durationText, 50, yPosition_right_column, 10, timesRomanFont, rgb(0.431, 0.416, 0.42));
      
        // Check if the current element is the last one
        if (index === education_list.length - 1) {
            yPosition_right_column -= sectionSpacing;
        } else {
            yPosition_right_column -= sectionSpacing;
        }
      });
    }

    if (skills && skills.length > 0) {
      yPosition_right_column -= sectionSpacing;
      yPosition_right_column = drawSectionHeader_right('SKILLS', yPosition_right_column);
      if (isSkillsCategorized) {
        let lastValidIndex = -1;
        // First, identify the last valid index
        Object.keys(categorizedSkills).forEach((key, index) => {
            if (categorizedSkills[key] && categorizedSkills[key].length > 0) {
                lastValidIndex = index;
            }
        });

        Object.keys(categorizedSkills).forEach((key, index) => {
            // Check if the value is an empty list or null
            if (!categorizedSkills[key] || categorizedSkills[key].length === 0) {
                return; // Skip to the next iteration
            }
            let skillsText = categorizedSkills[key].join(', ');
            yPosition_right_column = drawTextWithWrappingSkillsCategory(key, 397, yPosition_right_column, fontsize, timesRomanBoldFont);
            yPosition_right_column += (lineHeightSpacing + 4);
            yPosition_right_column -= (lineHeightSpacing + 4);
            yPosition_right_column = drawTextWithWrappingSkillsCategory(skillsText, 397, yPosition_right_column, fontsize, timesRomanFont);
            yPosition_right_column += (lineHeightSpacing + 4);

            // Check if the current key is the last valid element in categorizedSkills
            yPosition_right_column -= sectionSpacing;
        });
        } else {
            const skillsText = skills.join(', ');
            yPosition_right_column = drawTextWithWrapping_right(skillsText, 397, yPosition_right_column, fontsize, timesRomanFont);
            yPosition_right_column += (lineHeightSpacing + 4);
            yPosition_right_column -= sectionSpacing;
        }
    }

    // Certificates
    if (certificates && certificates.length > 0) {
        yPosition_right_column -= sectionSpacing;
        yPosition_right_column = drawSectionHeader_right('CERTIFICATES', yPosition_right_column);
        certificates.forEach(cert => {
          const certNameText = (cert.name || '');
          const certDateText = cert.achieved_date ? String(cert.achieved_date) : '';
          yPosition_right_column = drawBulletedText_right(certNameText, 397, yPosition_right_column);
          yPosition_right_column = drawText_right(certDateText, 410, yPosition_right_column, 10, timesRomanFont, rgb(0.431, 0.416, 0.42))
          yPosition_right_column -= sectionSpacing;
        });
    }

    currentPageIndex = 0; // Start again with the first page
    page = getPage(currentPageIndex); // Access the first page

    // Header
    yPosition -= 15;
    yPosition = drawText_left(name || '', 50, yPosition, name_fontsize_template_3, timesRomanBoldFont, rgb(0.11, 0.533, 0.929));

    // Summary
    if (summary) {
      yPosition -= 40;
      yPosition = drawSectionHeader('SUMMARY', yPosition);
      yPosition = drawTextWithWrapping(summary, 50, yPosition, fontsize, timesRomanFont);
      yPosition += lineHeightSpacing + 4;
      yPosition -= sectionSpacing;
      yPosition -= sectionSpacing;
    } else {
      yPosition -= 40;
    }

    // Work Experience
    if (revisedWorkExp && revisedWorkExp.length > 0) {
      yPosition = drawSectionHeader('WORK EXPERIENCE', yPosition);
    
      revisedWorkExp.forEach((exp, index) => {
        const exp_title = `${exp.Experience.Title} at ${exp.Experience.Company}`;
        yPosition = drawTextWithWrapping(exp_title, 50, yPosition, fontsize, timesRomanBoldFont, 330);
        let location_text = '';
          if (exp.Experience.Location) {
            location_text = `${exp.Experience.Location} | ${exp.Experience.Duration}`;
          } else {
            location_text = `${exp.Experience.Duration}`
          }
        yPosition = drawTextWithWrapping(location_text, 50, yPosition, fontsize, timesRomanBoldFont, 330);
        exp.Experience.Responsibilities.forEach(resp => {
          if (showBoldKeywords) {
            yPosition = drawBulletedText(resp, 50, yPosition);
          } else {
            yPosition = drawBulletedTextNoBold(resp, 50, yPosition);
          }
        });
        // Check if the current element is the last one
        if (index === revisedWorkExp.length - 1) {
            yPosition += (lineHeightSpacing + 4);
            yPosition -= sectionSpacing;
        } else {
            yPosition += (lineHeightSpacing + 4);
            yPosition -= sectionSpacing;
        }
      });
    }
      
    // Project Experience
    if (revisedProjExp && revisedProjExp.length > 0) {
      yPosition -= sectionSpacing;
      yPosition = drawSectionHeader('PROJECT EXPERIENCE', yPosition);

      revisedProjExp.forEach((exp, index) => {
        if (exp.Experience) { // Ensure 'Experience' object exists
          const exp_title = `${exp.Experience.Title} at ${exp.Experience.Company}`;
          yPosition = drawTextWithWrapping(exp_title, 50, yPosition, fontsize, timesRomanBoldFont, 330);
          let location_text = '';
          if (exp.Experience.Location) {
            location_text = `${exp.Experience.Location} | ${exp.Experience.Duration}`;
          } else {
            location_text = `${exp.Experience.Duration}`
          }
          yPosition = drawTextWithWrapping(location_text, 50, yPosition, fontsize, timesRomanBoldFont, 330);
          exp.Experience.Responsibilities.forEach(resp => {
            if (showBoldKeywords) {
                yPosition = drawBulletedText(resp, 50, yPosition);
            } else {
                yPosition = drawBulletedTextNoBold(resp, 50, yPosition);
            }
          });
          // Check if the current element is the last one
          if (index === revisedProjExp.length - 1) {
            yPosition += (lineHeightSpacing + 4);
            yPosition -= sectionSpacing;
          } else {
            yPosition += (lineHeightSpacing + 4);
            yPosition -= sectionSpacing;
          }
        }
      });
    }
  };

  const generatePdf = async () => {
      const pdfDoc = await PDFDocument.create();
      const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
      const timesRomanBoldFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);
  
      if (selectedTemplate === 0) {
          await generateTemplate1(pdfDoc, timesRomanFont, timesRomanBoldFont);
      } else if (selectedTemplate === 1) {
          await generateTemplate2(pdfDoc, timesRomanFont, timesRomanBoldFont);
      } else if (selectedTemplate === 2) {
          await generateTemplate3(pdfDoc, timesRomanFont, timesRomanBoldFont);
      }
  
      const pdfBytes = await pdfDoc.save();
      const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfUrl(pdfUrl);
      setPdfBlob(pdfBlob);
  };

  const downloadPdf = () => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(pdfBlob);
    let jobPositionPart = resumeData?.namingInfo?.jobPosition ? `_${resumeData?.namingInfo?.jobPosition}` : '';
    let companyNamePart = resumeData?.namingInfo?.companyName ? `_${resumeData?.namingInfo?.companyName}` : '';

    link.download = `${data.personal_info['First Name']}_${data.personal_info['Last Name']}${jobPositionPart}${companyNamePart}_Resume.pdf`;
    link.click();
  };

  useEffect(() => {
    console.log('Selected template changed:', selectedTemplate); 
    generatePdf();
  }, [selectedTemplate, showBoldKeywords, fontsize, name_fontsize, template_1_section_header, 
    name_fontsize_template_3, sectionSpacing, sectionBetweenLine, lineHeightSpacing]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose(); // Close the modal if clicked outside
      }
    };

    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Cleanup the event listener when the component is unmounted
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);


	return (
		<div className="add-application-modal-overlay">
			<div className="add-application-modal-content" ref={dropdownRef} onClick={e => e.stopPropagation()}>
				<div className="add-application-modal-scroll-content">
            <div className="resume-popup-modal-header">
                <div className="left-section">
                    <h2 className="job-description-modal-title">Resume Preview</h2>
                    <img
                        className="resume-popup-export-button"
                        onClick={downloadPdf}
                        src='/img/dashboard_download.png'
                        alt="Download"
                    />
                </div>
                <img 
                    src="/img/ic_round-close.png" 
                    alt="Close" 
                    className="close-icon" 
                    onClick={handleClose} // This function will close the modal
                />
            </div>
					<div className="resume-modal-preview-section">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
              {pdfUrl ? (
              <div style={{ marginBottom: '20px', paddingTop: '20px', paddingBottom: '20px', backgroundColor: '#fff'}}>

                <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#fff' }}>
                  <ZoomOutButton /> {/* Button to zoom out */}
                  <Zoom levels={[0.4, 0.8, 1.2, 1.6, 2.0, 2.4]} />
                  <ZoomInButton />  {/* Button to zoom in */}
                </div>

                <Viewer fileUrl={pdfUrl} plugins={[zoomPluginInstance]} />
              </div>
              ) : (
                <p>Loading PDF...</p>
              )}
            </Worker>
          </div>
				</div>
			</div>
		</div>
	);
};

export default ResumeModal;